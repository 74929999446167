import React from "react";
import styles from "./styles.module.css";
import { playIcon } from "../../../../resources/Images/images";

const HeroSection = () => {
  return (
    <div className={styles.topHeaderContainerStyle}>
      <div className={styles.topHeaderOutSideContainerStyle}>
        <div className={styles.topHeaderBlurContainerStyle}>
          <div className={styles.topHeaderInsideContainerStyle}>
            <p className={styles.headerTitleTextStyle}>
              The new standard of lost & found solutions.
            </p>
            <div className={styles.headerDescContainerStyle}>
              <p className={styles.headerTitleDescriptionTextStyle}>
                Foundi is a QR code solution created to recover lost items while
                protecting your identity and privacy.
              </p>
              <div className={styles.playBtnContainerStyle}>
                <div className={styles.imgContainerStyle}>
                  <img src={playIcon} className={styles.imgStyle} />
                </div>
                <p className={styles.playTextStyle}>Watch video</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;

import React, { useState, useRef } from "react";
import ModalPopUp from "../../../../components/ModalBox";
import styles from "./styles.module.css";
import TextInput from "../../../../components/TextInput";
import {
  darkCloseIcon,
  rightArrowLightImg,
} from "../../../../resources/Images/images";

const ReturnSection = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div style={{ position: "relative" }}>
      <div className={styles.returnContainerStyle}>
        <p className={styles.returnTextStyle}>👋 Have you found a lost-item?</p>
        <a
          className={styles.returnAnchorTextStyle}
          onClick={() => setOpen(true)}
        >
          Return it now
        </a>
      </div>
      <ModalPopUp open={open} handleClose={handleClose}>
        <div className={styles.modalMainContainerStyle}>
          <div className={styles.topModalContainerStyle}>
            <p className={styles.needHelpTextStyle}>Need help?</p>
            <div
              className={styles.closeContainerStyle}
              onClick={() => handleClose()}
            >
              <div className={styles.imgWrapperStyle}>
                <img src={darkCloseIcon} className={styles.imgStyle} />
              </div>
            </div>
          </div>
          <div className={styles.modalContainerStyle}>
            <div className={styles.modalContentStyle}>
              <p className={styles.modalHeaderTextStyle}>Return item</p>
              <p className={styles.modalDescTextStyle}>
                Scan QR or enter the SNO number present on the tag attached to
                the found-item to get in touch with the owner of the item.
              </p>
            </div>
            <TextInput
              // error={true}
              textInput={true}
              rightIcon={rightArrowLightImg}
              placeholderName={"Enter SNO code"}
              type={"text"}
              onChangeText={(e) => console.log(e.target.value)}
            />
            <p className={styles.scanTextStyle}>Scan QR code</p>
          </div>
        </div>
      </ModalPopUp>
    </div>
  );
};

export default ReturnSection;

import React from "react";
import styles from "./styles.module.css";
import {
  darkDownPointer,
  darkUpPointer,
} from "../../../../resources/Images/images";
import { Rating } from "react-simple-star-rating";
import { HiStar } from "react-icons/hi";

const ReviewsSection = () => {
  const Data = [
    {
      id: 1,
      name: "Hank Green",
      rating: 3.5,
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse risus urna, rutrum non quam in. Lorem ipsum dolor sit. Suspendisse risus urna, rutrum non quam in. Lorem ipsum dolor sit. Suspendisse risus urna, rutrum non quam in.",
      date: "Dec 24, 2022, Sweden",
    },
    {
      id: 2,
      name: "Hank Green",
      rating: 4,
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse risus urna, rutrum non quam in. Lorem ipsum dolor sit. Suspendisse risus urna, rutrum non quam in. Lorem ipsum dolor sit. Suspendisse risus urna, rutrum non quam in.",
      date: "Dec 24, 2022, Sweden",
    },
    {
      id: 3,
      name: "Hank Green",
      rating: 2,
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse risus urna, rutrum non quam in. Lorem ipsum dolor sit. Suspendisse risus urna, rutrum non quam in. Lorem ipsum dolor sit. Suspendisse risus urna, rutrum non quam in.",
      date: "Dec 24, 2022, Sweden",
    },
    {
      id: 4,
      name: "Hank Green",
      rating: 5,
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse risus urna, rutrum non quam in. Lorem ipsum dolor sit. Suspendisse risus urna, rutrum non quam in. Lorem ipsum dolor sit. Suspendisse risus urna, rutrum non quam in.",
      date: "Dec 24, 2022, Sweden",
    },
  ];
  const fillColorArray = ["#FFC700"];

  const customIcons = [
    {
      icon: <HiStar size={24} />,
    },
    {
      icon: <HiStar size={24} />,
    },
    {
      icon: <HiStar size={24} />,
    },
    {
      icon: <HiStar size={24} />,
    },
    {
      icon: <HiStar size={24} />,
    },
  ];
  return (
    <div className={styles.reviewContainerStyle}>
      <div>
        <p className={styles.reviewHeaderTextStyle}>4.3 stars, 136 reviews</p>
        <div className={styles.mostRecentContainerStyle}>
          <p className={styles.mostRecentTextStyle}>Most Recent</p>
          <div className={styles.imgWrapperStyle}>
            <img src={darkDownPointer} className={styles.imgStyle} />
          </div>
        </div>
      </div>
      <div className={styles.reviewListContainerStyle}>
        {Data.map((item, index) => {
          return (
            <div key={index} className={styles.reviewInsideContainerStyle}>
              <Rating
                customIcons={customIcons}
                initialValue={item.rating}
                transition
                allowFraction
                readonly={true}
                fillColorArray={fillColorArray}
              />
              <p className={styles.reviewDescTextStyle}>{item.desc}</p>
              <div className={styles.reviewNameAndDateContainerStyle}>
                <p className={styles.reviewerNameTextStyle}>{item.name}</p>
                <p className={styles.reviewSentDateTextStyle}>{item.date}</p>
              </div>
            </div>
          );
        })}
      </div>
      <p className={styles.viewMoreTextStyle}>View more reviews</p>
    </div>
  );
};

export default ReviewsSection;

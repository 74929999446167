import React, { useState } from "react";
import styles from "./styles.module.css";
import {
  helsinborgImg,
  londonImg,
  dubaiImg,
  leftArrowImg,
  rightArrowImg,
  crossArrowIcon,
} from "../../../../resources/Images/images";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
const Data = [
  // {
  //   id: 0,
  //   header: "EXPO 2020 - Dubai",
  //   description:
  //     "Foundi stalls and services are available for all the people who visit H22 Expo slated to happen between 31st December 2022, Address.",
  // },
  // {
  //   id: 1,
  //   header: "London’s New Year Eve 2023",
  //   description:
  //     "Foundi stalls and services are available for all the people who visit H22 Expo slated to happen between 31st December 2022, Address.",
  // },
  {
    id: 0,
    header: "Helsingborg City Expo 2022",
    description:
      "Foundi stalls and services are available for all the people who visit H22 Expo slated to happen between 31st December 2022, Address.",
  },
];
const EventsHeaderSection = () => {
  const { width } = useWindowDimensions();
  const [current, setCurrent] = useState(0);
  const [slide, setSlide] = useState();
  const [img, setImg] = useState(helsinborgImg);
  const [linktext, setLinkText] = useState("H22 Event");
  const length = Data.length;
  // const nextSlide = () => {
  //   setCurrent(current === length - 1 ? 0 : current + 1);
  //   setImg(
  //     current === length - 1
  //       ? dubaiImg
  //       : current === length - 2
  //       ? helsinborgImg
  //       : londonImg
  //   );
  //   setLinkText(
  //     current === length - 1
  //       ? "Dubai Expo"
  //       : current === length - 2
  //       ? "H22 Event"
  //       : "London Event"
  //   );
  //   setSlide(false);
  // };

  // const prevSlide = () => {
  //   setCurrent(current === 0 ? length - 1 : current - 1);
  //   setImg(
  //     current === 0
  //       ? "H22 Event"
  //       : current === length - 1
  //       ? "London Event"
  //       : "Dubai Event"
  //   );
  //   setLinkText(
  //     current === 0
  //       ? helsinborgImg
  //       : current === length - 1
  //       ? londonImg
  //       : dubaiImg
  //   );
  //   setSlide(true);
  // };

  return (
    <div
      style={{
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className={styles.eventScreenFitContainerStyle}>
        <div className={styles.eventOutsideContainerStyle}>
          <div className={styles.eventInsideContainerStyle}>
            {Data.map((item, index) => {
              return (
                <div>
                  {index === current && (
                    <>
                      <p className={styles.eventHeaderTextStyle}>
                        {item.header}
                      </p>
                      <p className={styles.eventDescriptionTextStyle}>
                        {item.description}
                      </p>
                    </>
                  )}
                </div>
              );
            })}
            <div className={styles.imageButtonsTopContainerStyle}>
              <div
                className={styles.leftArrowImgTopWrapperStyle}
                // onClick={() => prevSlide()}
              >
                <img src={leftArrowImg} className={styles.imgStyle} />
              </div>

              <div
                className={styles.rightArrowImgTopWrapperStyle}
                // onClick={() => nextSlide()}
              >
                <img src={rightArrowImg} className={styles.imgStyle} />
              </div>
            </div>
            <div className={styles.imageButtonsContainerStyle}>
              <div
                className={styles.leftArrowImgWrapperStyle}
                // onClick={() => prevSlide()}
              >
                <img src={leftArrowImg} className={styles.imgStyle} />
              </div>
              <div className={styles.imgWrapperStyle}>
                <img src={img} className={styles.imgStyle} />
                <div className={styles.linkContainerStyle}>
                  <div className={styles.arrowImgWrapperStyle}>
                    <img src={crossArrowIcon} className={styles.imgStyle} />
                  </div>
                  <p className={styles.linkTextStyle}>{linktext}</p>
                </div>
              </div>

              <div
                className={styles.rightArrowImgWrapperStyle}
                // onClick={() => nextSlide()}
              >
                <img src={rightArrowImg} className={styles.imgStyle} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsHeaderSection;

import React from "react";
import styles from "./styles.module.css";
import { aboutVideoImg } from "../../../../resources/Images/images";

const AboutVideoSection = () => {
  return (
    <div className={styles.heroMainContainerStyle}>
      <div className={styles.heroinsideContainerStyle}>
        <div className={styles.headerContentStyle}>
          <p className={styles.headerTextStyle}>
            A modern solution to an ancient problem
          </p>
          <p className={styles.headerDescTextStyle}>
            Our story - Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Mauris non lacus ac elit dictum tempor sed id lectus. Duis suscipit
            quis nisl nec consectetur. Donec scelerisque congue felis non
            volutpat. Etiam sed magna bibendum, pharetra diam aliquam, vehicula
            magna. Aenean non accumsan dolor, in porta lectus. Ut vel semper
            eros.
          </p>
        </div>
        <div className={styles.heroImgWrapperStyle}>
          <img src={aboutVideoImg} className={styles.imgStyle} />
        </div>
        <div className={styles.aboutAppHeaderContainerStyle}>
          <p className={styles.aboutAppHeaderTextStyle}>
            Do you know,
            <span className={styles.boldHeaderTextStyle}>
              &nbsp;19.4 billion kr&nbsp;
            </span>
            worth of belongings were lost by the Swedish population in the last
            year alone
          </p>
        </div>
        <div className={styles.aboutAppDescContainerStyle}>
          <div className={styles.aboutAppDescInnerContainerStyle}>
            <p className={styles.aboutAppDescHeaderTextStyle}>55 hours</p>
            <p className={styles.aboutAppDescHeaderContentTextStyle}>
              Average time each person spends searching for lost or misplaced
              items per year
            </p>
          </div>
          <div className={styles.aboutAppDescInnerContainerStyle}>
            <p className={styles.aboutAppDescHeaderTextStyle}>96%</p>
            <p className={styles.aboutAppDescHeaderContentTextStyle}>
              Percentage of the Swedish population that lose or misplace their
              belongings every year
            </p>
          </div>
          <div className={styles.aboutAppDescInnerContainerStyle}>
            <p className={styles.aboutAppDescHeaderTextStyle}>1.89</p>
            <p className={styles.aboutAppDescHeaderContentTextStyle}>
              Average amount of items each person loses or misplaces in a year
            </p>
          </div>
          <div className={styles.aboutAppDescInnerContainerStyle}>
            <p className={styles.aboutAppDescHeaderTextStyle}>2000kr</p>
            <p className={styles.aboutAppDescHeaderContentTextStyle}>
              Average cost per lost or misplaced belonging
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutVideoSection;

import React from "react";
import styles from "./styles.module.css";
import {
  redBuyIcon,
  redHeartIcon,
  redLockIcon,
  redMessageIcon,
  redPlusIcon,
  redTimeIcon,
} from "../../../../resources/Images/images";

const AboutEliminatingPrblmSection = () => {
  const PlanDetails = [
    {
      id: 1,
      desc: "Vast collection of products for registering belongings",
      image: redBuyIcon,
    },
    {
      id: 2,
      desc: "Easy setup and item registration process",
      image: redPlusIcon,
    },
    {
      id: 3,
      desc: "Messenger functions without having an app",
      image: redMessageIcon,
    },
    {
      id: 4,
      desc: "Increased privacy for both users and finders",
      image: redLockIcon,
    },
    {
      id: 5,
      desc: "Life-long use, no battery or charging required",
      image: redTimeIcon,
    },
    {
      id: 6,
      desc: "Sustainable solution + Reliable everywhere and every time",
      image: redHeartIcon,
    },
  ];
  return (
    <div className={styles.aboutSubscriptionsMainConatinerStyle}>
      <div className={styles.aboutSubscriptionsHeaderContainerStyle}>
        <p className={styles.aboutSubScriptionsHeaderTextStyle}>
          Eliminating the problems that come with Bluetooth/GPS trackers
        </p>
        <p className={styles.aboutSubScriptionsDescTextStyle}>
          From security to reliability of electronic devices, the Bluetooth/GPS
          lost & found solutions have many issues. Here are a few features that
          puts Foundi apart from what competitors have to offer.
        </p>
      </div>
      <div className={styles.plansMainContainerStyle}>
        {PlanDetails.map((item, index) => {
          return (
            <div
              key={"plans" + index}
              className={styles.planInsideRedContainerStyle}
            >
              <div className={styles.planTimeDetailsContainerStyle}>
                <div className={styles.checkImgWrapperStyle}>
                  <img src={item.image} className={styles.imgStyle} />
                </div>

                <div className={styles.limitContainerStyle}>
                  <p className={styles.planTextStyle}>{item.desc}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AboutEliminatingPrblmSection;

import React from "react";
import styles from "./styles.module.css";
import {
  blueCheckImg,
  greenCheckImg,
} from "../../../../resources/Images/images";

const AboutSubscriptionSection = () => {
  const PlanDetails = [
    {
      id: 1,
      tags: "Starter plan",
      price: "$0",
      time: "/lifetime",
      limit: "Limit maxes at 1 item",
      validity: "Lifetime validity",
      image: greenCheckImg,
    },
    {
      id: 2,
      tags: "Upto 3 tags",
      price: "$299",
      time: "/year",
      limit: "Limit maxes at 3 items",
      validity: "365 days of validity",
      image: blueCheckImg,
    },
    {
      id: 3,
      tags: "Upto 5 tags",
      price: "$449",
      time: "/year",
      limit: "Limit maxes at 5 items",
      validity: "365 days of validity",
      image: blueCheckImg,
    },
    {
      id: 4,
      tags: "Upto 7 tags",
      price: "$649",
      time: "/year",
      limit: "Limit maxes at 7 items",
      validity: "365 days of validity",
      image: blueCheckImg,
    },
    {
      id: 5,
      tags: "Upto 9 tags",
      price: "$849",
      time: "/year",
      limit: "Limit maxes at 9 items",
      validity: "365 days of validity",
      image: blueCheckImg,
    },
    {
      id: 6,
      tags: "Upto n tags",
      price: "$nx89",
      time: "/year",
      limit: "Limit maxes at n items",
      validity: "365 days of validity",
      image: blueCheckImg,
    },
  ];
  return (
    <div className={styles.aboutSubscriptionsMainConatinerStyle}>
      <div className={styles.aboutSubscriptionsHeaderContainerStyle}>
        <p className={styles.aboutSubScriptionsHeaderTextStyle}>
          We’re a subscription based service
        </p>
        <p className={styles.aboutSubScriptionsDescTextStyle}>
          However, as a part of promotional gift, users can enjoy 365 days of
          serives for free. The following are the maximum prices, the minimum
          price that users have to pay will vary depending on what active plan
          they are upgrading from.
        </p>
      </div>
      <div className={styles.plansMainContainerStyle}>
        {PlanDetails.map((item, index) => {
          return (
            <div
              key={"plans" + index}
              className={
                item.image === greenCheckImg
                  ? styles.planInsideGreenContainerStyle
                  : styles.planInsideBlueContainerStyle
              }
            >
              <div className={styles.planHeaderContainerStyle}>
                <p className={styles.planSmallTextStyle}>{item.tags}</p>
                <p className={styles.planPriceTextStyle}>
                  {item.price}
                  <span className={styles.planDetailsTextStyle}>
                    {item.time}
                  </span>
                </p>
              </div>
              <div className={styles.planTimeDetailsContainerStyle}>
                <div className={styles.limitContainerStyle}>
                  <div className={styles.checkImgWrapperStyle}>
                    <img src={item.image} className={styles.imgStyle} />
                  </div>
                  <p className={styles.planTextStyle}>{item.limit}</p>
                </div>
                <div className={styles.limitContainerStyle}>
                  <div className={styles.checkImgWrapperStyle}>
                    <img src={item.image} className={styles.imgStyle} />
                  </div>
                  <p className={styles.planTextStyle}>{item.validity}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AboutSubscriptionSection;

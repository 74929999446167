import React from "react";
import styles from "./styles.module.css";
import Navbar from "../../components/NavBar";
import EventsHeaderSection from "./Sections/EventsHeaderSection";
import LearnFoundiSection from "./Sections/LearnFoundiSection";
import Footer from "../../components/Footer";

const EventsPage = () => {
  return (
    <div className={styles.mainContainerStyle}>
      <div className={styles.mainInsideContainerStyle}>
        <Navbar navWhite={true} />
      </div>
      {EventsHeaderSection()}
      {LearnFoundiSection()}
      {Footer()}
    </div>
  );
};

export default EventsPage;

import React, { useState } from "react";
import styles from "./styles.module.css";
import { darkCloseIcon, googleIcon } from "../../resources/Images/images";
import { useNavigate } from "react-router-dom";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";

const SignupPage = () => {
  const navigate = useNavigate();
  const [pwVisible, setPwVisible] = useState(false);
  const TopNavSection = () => {
    return (
      <div className={styles.closeContainerStyle}>
        <p className={styles.stepsTextStyle}>Step 1 of 3</p>
        <div
          className={styles.closeIconWrapperStyle}
          onClick={() => navigate(-1)}
        >
          <img src={darkCloseIcon} className={styles.imgStyle} />
        </div>
      </div>
    );
  };
  const step1SignUp = () => {
    return (
      <div className={styles.signUpInsideContainerStyle}>
        <div className={styles.createAccountContainerStyle}>
          <div className={styles.topContainerStyle}>
            <p className={styles.createTextStyle}>Create an account</p>
            <p className={styles.haveAnAccTextStyle}>
              Have an account?&nbsp;
              <span className={styles.loginTextStyle}>Log in </span>
            </p>
          </div>
          {true && (
            <div className={styles.inputContainerStyle}>
              <TextInput
                textInput={true}
                labelName={"First name"}
                placeholderName={"Enter your first name"}
                type={"text"}
                onChangeText={(e) => console.log(e.target.value)}
              />
              <TextInput
                textInput={true}
                labelName={"Last name"}
                placeholderName={"Enter your last name"}
                type={"text"}
                onChangeText={(e) => console.log(e.target.value)}
              />
            </div>
          )}
          {/* {step2 && (
            <div className={styles.inputContainerStyle}>
              <TextInput
                textInput={true}
                labelName={"Email"}
                placeholderName={"Enter your email ID"}
                type={"text"}
                onChangeText={(e) => console.log(e.target.value)}
              />
              <TextInput
                textInput={true}
                labelName={"Password"}
                passWord={true}
                visible={pwVisible}
                onClick={() => setPwVisible(!pwVisible)}
                placeholderName={"6+ characters, 1 capital"}
                type={"text"}
                onChangeText={(e) => console.log(e.target.value)}
              />
            </div>
          )} */}
          <div className={styles.buttonContainerStyle}>
            <Button
              title="Next"
              btnStyles={styles.nextBtnStyle}
              // onClick={() => setOpen(true)}
            />
            <Button
              image={googleIcon}
              title="Continue with Google"
              btnStyles={styles.googleBtnStyle}
              // onClick={() => setOpen(true)}
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className={styles.progressContainerStyle}></div>
      <div className={styles.signupContainerStyle}>
        {TopNavSection()}
        {step1SignUp()}
      </div>
    </>
  );
};

export default SignupPage;

import React, { useState } from "react";
import styles from "./styles.module.css";
import {
  product1Img,
  product2Img,
  plusIcon,
  minusIcon,
} from "../../../../resources/Images/images";
import Button from "../../../../components/Button";
import { useNavigate } from "react-router-dom";

const ProductDetailSection = () => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const [items, setItems] = useState(null);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const Data = [
    {
      id: 1,
      image: product1Img,
    },
    {
      id: 2,
      image: product2Img,
    },
    {
      id: 3,
      image: product1Img,
    },
  ];
  const length = Data.length;
  const selectedImage = (index) => {
    setCurrent(index);
  };
  const AddCartFunction = () => {
    setItems(1);
  };
  const cartDecrement = () => {
    setItems(items - 1);
  };
  const cartIncrement = () => {
    setItems(items + 1);
  };
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      setCurrent(current === length - 1 ? 0 : current + 1);
    } else if (isRightSwipe) {
      setCurrent(current === 0 ? length - 1 : current - 1);
    }
  };
  return (
    <div className={styles.productDetailsContainerStyle}>
      <div className={styles.productDetailsInnerContainerStyle}>
        <div className={styles.imgContainerStyle}>
          {Data.map((item, index) => {
            return (
              <div
                key={"image" + index}
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
              >
                {current === index && (
                  <div className={styles.imgWrapperStyle}>
                    <img src={item.image} className={styles.imgStyle} />
                  </div>
                )}
              </div>
            );
          })}
          <div className={styles.dotsWrapperStyle}>
            {Data.map((item, index) => {
              return (
                <div
                  key={"index" + index}
                  className={
                    index === current
                      ? styles.dotsSelectedStyle
                      : styles.dotsStyle
                  }
                  onClick={() => selectedImage(index)}
                ></div>
              );
            })}
          </div>
        </div>
        <div>
          <p className={styles.productNameTextStyle}>
            Regular tags - A pack of 4
          </p>
          <p className={styles.productDescriptionTextStyle}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            risus urna, rutrum non quam in. Lorem ipsum dolor sit consectetur.
          </p>
          <p className={styles.productDiscountPriceTextStyle}>
            $59.00 &nbsp;
            <span className={styles.productOriginalPriceTextStyle}>$99.00</span>
          </p>
          <div className={styles.btnContainerStyle}>
            {items > 0 ? (
              <div className={styles.cartNumberContainerStyle}>
                <div
                  className={styles.iconWrapperStyle}
                  onClick={() => cartDecrement()}
                >
                  <img src={minusIcon} className={styles.imgStyle} />
                </div>
                <p>{items}</p>
                <div
                  className={styles.iconWrapperStyle}
                  onClick={() => cartIncrement()}
                >
                  <img src={plusIcon} className={styles.imgStyle} />
                </div>
              </div>
            ) : (
              <Button
                btnStyles={styles.whiteButtonStyle}
                title={"Add to cart"}
                onClick={() => AddCartFunction()}
              />
            )}
            <Button
              btnStyles={styles.blueButtonStyle}
              title={"Buy now"}
              onClick={() => navigate("/checkout")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailSection;

import React from "react";
import { Routes, Route } from "react-router-dom";
import {
  HomePage,
  EventsPage,
  ProductsPage,
  ProductDetailsPage,
  AboutPage,
  CheckOutPage,
  SignupPage,
  LoginPage,
  ChatPage,
  OrderHistoryPage,
  PrivacyPolicyPage,
} from "../pages";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/events" element={<EventsPage />} />
      {/* <Route path="/products" element={<ProductsPage />} /> */}
      <Route path="/productdetails" element={<ProductDetailsPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/checkout" element={<CheckOutPage />} />
      <Route path="/chat" element={<ChatPage />} />
      <Route path="/orderhistory" element={<OrderHistoryPage />} />
      <Route path="/privacy" element={<PrivacyPolicyPage />} />
    </Routes>
  );
}

export default AppRoutes;

import React from "react";
import styles from "./styles.module.css";
import {
  fbIcon,
  instaIcon,
  twitterIcon,
  linkedinIcon,
} from "../../resources/Images/images";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.outsideFooterContainerStyle}>
      <div className={styles.footerContainerStyle}>
        <div className={styles.footerLinksStyle}>
          <a className={styles.footerlinkwrapperStyle}>
            <span className={styles.footerlinkEmojiStyle}>🤝</span>
            <p className={styles.footerlinkTextStyle}>Join community</p>
          </a>
          <a className={styles.footerlinkwrapperStyle}>
            <span className={styles.footerlinkEmojiStyle}>📧</span>
            <p className={styles.footerlinkTextStyle}>info@foundiapp.com</p>
          </a>
          <a className={styles.footerlinkwrapperStyle}>
            <span className={styles.footerlinkEmojiStyle}>🤙</span>
            <p className={styles.footerlinkTextStyle}>+46(0)724430170</p>
          </a>
          <a className={styles.footerlinkwrapperStyle}>
            <span className={styles.footerlinkEmojiStyle}>📍</span>
            <p className={styles.footerlinkTextStyle}>Visit our office</p>
          </a>
        </div>
        <div className={styles.footerBottomContainer1Style}>
          <div className={styles.policyContainerStyle}>
            <a
              className={styles.privacyTextStyle}
              onClick={() => navigate("/privacy")}
            >
              Privacy Policy
            </a>
            <a className={styles.privacyTextStyle}>Terms of Service</a>
          </div>
          <div className={styles.socialContainerStyle}>
            <div className={styles.socialIconWrapperStyle}>
              <img src={fbIcon} className={styles.imgStyle} />
            </div>
            <div className={styles.socialIconWrapperStyle}>
              <img src={instaIcon} className={styles.imgStyle} />
            </div>
            <div className={styles.socialIconWrapperStyle}>
              <img src={twitterIcon} className={styles.imgStyle} />
            </div>
            <div className={styles.socialIconWrapperStyle}>
              <img src={linkedinIcon} className={styles.imgStyle} />
            </div>
          </div>
          <div>
            <p className={styles.copyRightsTextStyle}>
              ©2022, All right reserved - Foundi
            </p>
          </div>
        </div>
        <div className={styles.footerBottomContainer2Style}>
          <div className={styles.socialContainer2Style}>
            <div className={styles.socialIconWrapperStyle}>
              <img src={fbIcon} className={styles.imgStyle} />
            </div>
            <div className={styles.socialIconWrapperStyle}>
              <img src={instaIcon} className={styles.imgStyle} />
            </div>
            <div className={styles.socialIconWrapperStyle}>
              <img src={twitterIcon} className={styles.imgStyle} />
            </div>
            <div className={styles.socialIconWrapperStyle}>
              <img src={linkedinIcon} className={styles.imgStyle} />
            </div>
          </div>
          <div className={styles.policyContainer2Style}>
            <a className={styles.privacyTextStyle}>Privacy Policy</a>
            <a className={styles.privacyTextStyle}>Terms of Service</a>
          </div>

          <div>
            <p className={styles.copyRightsTextStyle}>
              ©2022, All right reserved - Foundi
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React, { useState } from "react";
import styles from "./styles.module.css";
import {
  darkCloseIcon,
  downSmallArrowIcon,
  upSmallArrowIcon,
  darkLeftArrowIcon,
  msgSendIcon,
  galleryIcon,
  chatOptionIcon,
  blueCheckIcon,
  blackCheckIcon,
  darkRightArrow,
  squareTickIcon,
  paperIcon,
} from "../../resources/Images/images";
import TextInput from "../../components/TextInput";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import MenuPopover from "../../components/Popover";
import ModalPopUp from "../../components/ModalBox";
import Button from "../../components/Button";

const ChatPage = () => {
  const { width, height } = useWindowDimensions();
  const [view, setView] = useState(false);
  const [anchor1, setAnchorl] = useState(null);
  const [anchor2, setAnchor2] = useState(null);
  const [open, setOpen] = useState(false);
  const divRefChats = React.useRef();
  const divRefOptions = React.useRef();
  const chatList = [
    {
      id: 1,
      image: darkCloseIcon,
      productName: "My wallet",
      productSnoNo: " (SNO#1E46MP)",
      finder: "Finder:",
      msgtext: " Lorem ipsum dolor sit amet  consec tetur adipiscing elit. ",
      date: "Oct 20",
      noOfMsg: "2",
    },
    {
      id: 2,
      image: darkCloseIcon,
      productName: "My wallet",
      productSnoNo: " (SNO#1E46MP)",
      finder: "Finder:",
      msgtext: " Lorem ipsum dolor sit amet  consec tetur adipiscing elit. ",
      date: "Oct 20",
      noOfMsg: "2",
    },
    {
      id: 3,
      image: darkCloseIcon,
      productName: "My wallet",
      productSnoNo: " (SNO#1E46MP)",
      finder: "Finder:",
      msgtext: " Lorem ipsum dolor sit amet  consec tetur adipiscing elit. ",
      date: "Oct 20",
      noOfMsg: "2",
    },
    {
      id: 4,
      image: darkCloseIcon,
      productName: "My wallet",
      productSnoNo: " (SNO#1E46MP)",
      finder: "Finder:",
      msgtext: " Lorem ipsum dolor sit amet  consec tetur adipiscing elit. ",
      date: "Oct 20",
      noOfMsg: "2",
    },
  ];
  const Messages = [
    {
      id: 1,
      msg: "Hi",
      productOwner: "Sender",
      time: "11.23",
    },
    {
      id: 2,
      msg: "have you reached?",
      productOwner: "Sender",
      time: "11.23",
    },
    {
      id: 3,
      msg: "Hey.",
      productOwner: "Receiver",
      time: "11.23",
    },
    {
      id: 4,
      msg: "Lorem ipsum dolor sit am et, consec tetur.",
      productOwner: "Receiver",
      time: "11.23",
    },
    {
      id: 5,
      msg: "Okay?",
      productOwner: "Receiver",
      time: "11.23",
    },
    {
      id: 6,
      msg: "Hi..2",
      productOwner: "Sender",
      time: "11.23",
    },
    {
      id: 7,
      msg: "Hi...3",
      productOwner: "Receiver",
      time: "11.23",
    },
    {
      id: 8,
      msg: "Hi....p",
      productOwner: "Sender",
      time: "11.23",
    },
    {
      id: 9,
      msg: "Hipoipoipo",
      productOwner: "Sender",
      time: "11.23",
    },
    {
      id: 10,
      msg: "Hi",
      productOwner: "Sender",
      time: "11.23",
    },
    {
      id: 11,
      msg: "have you reached?",
      productOwner: "Sender",
      time: "11.23",
    },
    {
      id: 12,
      msg: "Hey.",
      productOwner: "Receiver",
      time: "11.23",
    },
    {
      id: 13,
      msg: "Lorem ipsum dolor sit am et, consec tetur.",
      productOwner: "Receiver",
      time: "11.23",
    },
    {
      id: 14,
      msg: "Okay?",
      productOwner: "Receiver",
      time: "11.23",
    },
    {
      id: 15,
      msg: "Hi..2",
      productOwner: "Sender",
      time: "11.23",
    },
    {
      id: 16,
      msg: "Hi...3",
      productOwner: "Receiver",
      time: "11.23",
    },
    {
      id: 17,
      msg: "Hi....p",
      productOwner: "Sender",
      time: "11.23",
    },
    {
      id: 18,
      msg: "Hipoipoipo",
      productOwner: "Sender",
      time: "11.23",
    },
  ];
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOptions = (event) => {
    setAnchor2(divRefOptions.current);
  };
  const handleClickChats = (event) => {
    setAnchorl(divRefChats.current);
  };
  const handleCloseOptions = () => {
    setAnchor2(null);
  };
  const handleCloseChats = () => {
    setAnchorl(null);
  };
  const openOptions = Boolean(anchor2);
  const id = openOptions ? "simple-popover" : undefined;

  const openChat = Boolean(anchor1);
  const idPop = openChat ? "simple-popover" : undefined;
  const renderTopNavSection = () => {
    return (
      <div className={styles.topMenuNavContainerStyle}>
        <div
          className={styles.topAllChatsMenuContainerStyle}
          ref={divRefChats}
          onClick={handleClickChats}
        >
          <p className={styles.chatMenuTextStyle}>All chats</p>
          <img
            src={downSmallArrowIcon}
            className={styles.imgSmallWrapperStyle}
          />
        </div>
        <div className={styles.imgWrapperStyle}>
          <img src={darkCloseIcon} className={styles.imgStyle} />
        </div>
      </div>
    );
  };
  const renderChatMessagesContainerStyle = () => {
    return (
      <div className={styles.chatMsgContainerStyle}>
        {width > 867 && (
          <div className={styles.chatListContainerStyle}>
            {chatList.map((item, index) => {
              return (
                <div
                  key={"chatDetails" + index}
                  className={styles.chatBoxContainerStyle}
                >
                  <div className={styles.chatProductImgStyle}>
                    <div className={styles.chatProductImgWapperStyle}>
                      <img src={item.image} className={styles.imgStyle} />
                    </div>
                  </div>
                  <div className={styles.chatNameAnsMsgContainerStyle}>
                    <div className={styles.productMsgTopContainerStyle}>
                      <p className={styles.productNameTextStyle}>
                        {item.productName}
                        <span className={styles.productSnoTextStyle}>
                          {item.productSnoNo}
                        </span>
                      </p>
                      <p className={styles.productChatDateTextStyle}>
                        {item.date}
                      </p>
                    </div>
                    <div className={styles.productMsgTopContainerStyle}>
                      <p className={styles.productFinderTextStyle}>
                        {item.finder}
                        <span className={styles.productMsgTextStyle}>
                          {item.msgtext}
                        </span>
                      </p>
                      <div className={styles.noOfMsgTextWrapperStyle}>
                        <p className={styles.noOfMsgTextStyle}>
                          {item.noOfMsg}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {width > 867 && (
          <div className={styles.chatMsgViewContainerStyle}>
            <div className={styles.chatMsgViewHeaderStyle}>
              <div className={styles.chatMsgHeaderInsideWrapperStyle}>
                <div className={styles.chatMsgViewTopProductImgWrapperStyle}>
                  <div className={styles.chatViewProductImgWapperStyle}>
                    <img src={darkCloseIcon} className={styles.imgStyle} />
                  </div>
                </div>
                <div className={styles.chatMsgNameSnoWrapperStyle}>
                  <p className={styles.chatViewProductNameTextStyle}>
                    Sony XM4
                  </p>
                  <p className={styles.chatViewProductSnoNoTextStyle}>
                    SNO#F10B7NR
                  </p>
                </div>
              </div>
              <div
                className={styles.chatViewProductImgWapperStyle}
                ref={divRefOptions}
                onClick={handleClickOptions}
              >
                <img src={chatOptionIcon} className={styles.imgStyle} />
              </div>
            </div>
            <div className={styles.msgSContainerStyle}>
              {Messages.map((item, index) => {
                return (
                  <div
                    key={"message" + index}
                    className={
                      item.productOwner === "Sender"
                        ? styles.msgOwnerWrapperStyle
                        : styles.msgSenderWrapperStyle
                    }
                  >
                    <div
                      className={
                        item.productOwner === "Sender"
                          ? styles.msgTextBlackWrapperStyle
                          : styles.msgTextWhiteWrapperStyle
                      }
                    >
                      <p
                        className={
                          item.productOwner === "Sender"
                            ? styles.msgTextBlackStyle
                            : styles.msgTextWhiteStyle
                        }
                      >
                        {item.msg}
                      </p>
                    </div>
                    <p>{item.time}</p>
                  </div>
                );
              })}
            </div>
            <div className={styles.msgInputContainerStyle}>
              <TextInput
                textInput={true}
                chatInputIcon={galleryIcon}
                rightIcon={msgSendIcon}
                inputStyles={styles.chatInputStyle}
                placeholderName={"Type here . . ."}
                type={"text"}
                onChangeText={(e) => console.log(e.target.value)}
              />
            </div>
          </div>
        )}
        {!view && width < 867 && (
          <div className={styles.chatListContainerStyle}>
            {chatList.map((item, index) => {
              return (
                <div
                  key={"chatDetails" + index}
                  className={styles.chatBoxContainerStyle}
                  onClick={() => setView(true)}
                >
                  <div className={styles.chatProductImgStyle}>
                    <div className={styles.chatProductImgWapperStyle}>
                      <img src={item.image} className={styles.imgStyle} />
                    </div>
                  </div>
                  <div className={styles.chatNameAnsMsgContainerStyle}>
                    <div className={styles.productMsgTopContainerStyle}>
                      <p className={styles.productNameTextStyle}>
                        {item.productName}
                        <span className={styles.productSnoTextStyle}>
                          {item.productSnoNo}
                        </span>
                      </p>
                      <p className={styles.productChatDateTextStyle}>
                        {item.date}
                      </p>
                    </div>
                    <div className={styles.productMsgTopContainerStyle}>
                      <p className={styles.productFinderTextStyle}>
                        {item.finder}
                        <span className={styles.productMsgTextStyle}>
                          {item.msgtext}
                        </span>
                      </p>
                      <div className={styles.noOfMsgTextWrapperStyle}>
                        <p className={styles.noOfMsgTextStyle}>
                          {item.noOfMsg}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {view && width < 867 && (
          <div className={styles.chatMsgViewContainerStyle}>
            <div className={styles.chatMsgViewHeaderStyle}>
              <div className={styles.chatMsgHeaderInsideWrapperStyle}>
                <div
                  className={styles.chatViewProductImgWapperStyle}
                  onClick={() => setView(false)}
                >
                  <img src={darkLeftArrowIcon} className={styles.imgStyle} />
                </div>
                <div className={styles.chatMsgViewTopProductImgWrapperStyle}>
                  <div className={styles.chatViewProductImgWapperStyle}>
                    <img src={darkCloseIcon} className={styles.imgStyle} />
                  </div>
                </div>
                <div className={styles.chatMsgNameSnoWrapperStyle}>
                  <p className={styles.chatViewProductNameTextStyle}>
                    Sony XM4
                  </p>
                  <p className={styles.chatViewProductSnoNoTextStyle}>
                    SNO#F10B7NR
                  </p>
                </div>
              </div>
              <div
                className={styles.chatViewProductImgWapperStyle}
                ref={divRefOptions}
                onClick={handleClickOptions}
              >
                <img src={chatOptionIcon} className={styles.imgStyle} />
              </div>
            </div>
            <div className={styles.msgSContainerStyle}>
              {Messages.map((item, index) => {
                return (
                  <div
                    key={"message" + index}
                    className={
                      item.productOwner === "Sender"
                        ? styles.msgOwnerWrapperStyle
                        : styles.msgSenderWrapperStyle
                    }
                  >
                    <div
                      className={
                        item.productOwner === "Sender"
                          ? styles.msgTextBlackWrapperStyle
                          : styles.msgTextWhiteWrapperStyle
                      }
                    >
                      <p
                        className={
                          item.productOwner === "Sender"
                            ? styles.msgTextBlackStyle
                            : styles.msgTextWhiteStyle
                        }
                      >
                        {item.msg}
                      </p>
                    </div>
                    <p>{item.time}</p>
                  </div>
                );
              })}
            </div>
            <div className={styles.msgInputContainerStyle}>
              <TextInput
                textInput={true}
                chatInputIcon={galleryIcon}
                rightIcon={msgSendIcon}
                inputStyles={styles.chatInputStyle}
                placeholderName={"Type here . . ."}
                type={"text"}
                onChangeText={(e) => console.log(e.target.value)}
              />
            </div>
          </div>
        )}
      </div>
    );
  };
  return (
    <div className={styles.chatMainContainerStyle}>
      {!view && renderTopNavSection()}
      {renderChatMessagesContainerStyle()}
      <MenuPopover
        id={id}
        open={openChat}
        anchorEl={anchor1}
        onClose={handleCloseChats}
      >
        <div className={styles.popupChatContainerStyle}>
          <div className={styles.popupChatTextWrapperStyle}>
            <p className={styles.popupBlueTextStyle}>All chats</p>
            <div className={styles.imgWrapperStyle}>
              <img src={blueCheckIcon} className={styles.imgStyle} />
            </div>
          </div>
          <div className={styles.popupChatTextWrapperStyle}>
            <p className={styles.popupBlackTextStyle}>All chats</p>
            <div className={styles.imgWrapperStyle}>
              <img src={blackCheckIcon} className={styles.imgStyle} />
            </div>
          </div>
          <div className={styles.popupChatTextWrapperStyle}>
            <p className={styles.popupBlackTextStyle}>All chats</p>
            <div className={styles.imgWrapperStyle}>
              <img src={blackCheckIcon} className={styles.imgStyle} />
            </div>
          </div>
        </div>
      </MenuPopover>
      <MenuPopover
        id={id}
        open={openOptions}
        anchorEl={anchor2}
        onClose={handleCloseOptions}
      >
        <div className={styles.optionPopupContainerStyle}>
          <div
            className={styles.optionPopupOutsideContanerStyle}
            onClick={() => setOpen(true)}
          >
            <div className={styles.optionInsiseContainerStyle}>
              <div className={styles.iconWrapperStyle}>
                <img src={squareTickIcon} className={styles.imgStyle} />
              </div>
              <p className={styles.optionPopuptextStyle}>Mark as recovered</p>
            </div>
            <div className={styles.imgWrapperStyle}>
              <img src={darkRightArrow} className={styles.imgStyle} />
            </div>
          </div>
          <div className={styles.optionPopupOutsideContanerStyle}>
            <div className={styles.optionInsiseContainerStyle}>
              <div className={styles.iconWrapperStyle}>
                <img src={paperIcon} className={styles.imgStyle} />
              </div>
              <p className={styles.optionPopuptextStyle}>Report chat</p>
            </div>
            <div className={styles.imgWrapperStyle}>
              <img src={darkRightArrow} className={styles.imgStyle} />
            </div>
          </div>
        </div>
      </MenuPopover>
      <ModalPopUp open={open} handleClose={handleClose}>
        <div className={styles.modalContainerStyle}>
          <div className={styles.modalContentWrapperStyle}>
            <p className={styles.modalHeaderTextStyle}>Recovered?</p>
            <p className={styles.modalDescTextStyle}>
              Are you sure you want to mark this item recovered. By doing so,
              this chat will be automatically archived.
            </p>
          </div>
          <div className={styles.modalBtnContainerStyle}>
            <Button
              title="Yes"
              btnStyles={styles.yesBtnStyle}
              // onClick={() => setOpen(true)}
            />
            <Button
              title="No"
              btnStyles={styles.noBtnStyle}
              onClick={() => handleClose()}
            />
          </div>
        </div>
      </ModalPopUp>
    </div>
  );
};

export default ChatPage;

import React from "react";
import styles from "./styles.module.css";
import { learnFoundiImg } from "../../../../resources/Images/images";

const LearnFoundiSection = () => {
  return (
    <div>
      <div className={styles.learnInsideContainerStyle}>
        <p className={styles.learnTopDescTextStyle}>
          About events and how people can take advatange of them. Lorem ipsum
          dolor sit amet, consectetur adipiscing elit. Suspendisse risus urna,
          rutrum non quam in. Lorem ipsum dolor sit. Suspendisse risus urna,
          rutrum non quam in. Lorem ipsum dolor sit. Suspendisse risus urna,
          rutrum non quam in.
        </p>
        <p className={styles.learnFoundiHeaderTextStyle}>
          Learn what Foundi can offer to your business
        </p>
        <div className={styles.learnBoxContainerStyle}>
          <div className={styles.learnImgWrapperStyle}>
            <img src={learnFoundiImg} className={styles.imgStyle} />
          </div>
          <div className={styles.leftBoxContentStyle}>
            <p className={styles.headerTextLeftStyle}>
              We drop them, we forget
            </p>
            <div className={styles.contentWrapperStyle}>
              <p className={styles.contentTextStyle}>
                them, and we lose them. Our valuables easily get lost in our
                everyday hassle. And, once lost, they are hard, if not
                impossible to get back. Why? Because to others, these items are
                anonymous. This is the problem we at Foundi want to solve.
                Foundi is a revolutionary digital service that connects owners
                to property.
              </p>
              <p className={styles.contentTextStyle}>
                We offers a subscription service for both customers and
                companies who don't want to save time and money on worrying
                about lost items. Contact us to learn more about what we can do
                for you and your company!
              </p>
            </div>
          </div>
        </div>
        <div className={styles.cardsOutsideContainerStyle}>
          <div className={styles.cardContainerStyle}>
            <p className={styles.headerTextLeftStyle}>For your employees</p>
            <p className={styles.contentTextStyle}>
              55 hours. That is how many hours an employee spends searching for
              work-related items: each year. It can be challenging for bigger
              organisations and enterprises to keep track of identical
              job-related items, such as work computers and key tags. Save time
              and money in buying replacements by integrating Foundi into your
              company.
            </p>
          </div>
          <div className={styles.cardContainerStyle}>
            <p className={styles.headerTextLeftStyle}>For your employees</p>
            <p className={styles.contentTextStyle}>
              55 hours. That is how many hours an employee spends searching for
              work-related items: each year. It can be challenging for bigger
              organisations and enterprises to keep track of identical
              job-related items, such as work computers and key tags. Save time
              and money in buying replacements by integrating Foundi into your
              company.
            </p>
          </div>
          <div className={styles.cardContainerStyle}>
            <p className={styles.headerTextLeftStyle}>For your employees</p>
            <p className={styles.contentTextStyle}>
              55 hours. That is how many hours an employee spends searching for
              work-related items: each year. It can be challenging for bigger
              organisations and enterprises to keep track of identical
              job-related items, such as work computers and key tags. Save time
              and money in buying replacements by integrating Foundi into your
              company.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnFoundiSection;

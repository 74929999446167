import React from "react";
import styles from "./styles.module.css";
import {
  appleAppStoreImg,
  googlePlayStoreImg,
} from "../../../../resources/Images/images";

const AboutAppSection = () => {
  return (
    <div className={styles.appContainerStyle}>
      <div className={styles.appImgWrapperStyle}>
        <img src={appleAppStoreImg} className={styles.imgStyle} />
      </div>
      <div className={styles.appImgWrapperStyle}>
        <img src={googlePlayStoreImg} className={styles.imgStyle} />
      </div>
    </div>
  );
};

export default AboutAppSection;

import React from "react";
import styles from "./styles.module.css";

const Button = (props) => {
  return (
    <button
      onClick={() => props.onClick()}
      className={[props.btnStyles, styles.buttonStyle].join(" ")}
    >
      {props.image && (
        <div className={styles.imgWrapperStyle}>
          <img src={props.image} className={styles.imgStyle} />
        </div>
      )}
      {props.title}
    </button>
  );
};

export default Button;

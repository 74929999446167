import React from "react";
import Navbar from "../../components/NavBar";
import styles from "../../pages/HomePage/styles.module.css";
import HeroSection from "./Sections/HeroSection";
import ReturnSection from "./Sections/ReturnSection";
import HowItWorksSection from "./Sections/HowItWorksSection";
import SustainabilitySection from "./Sections/SustainabilitySection";
import Footer from "../../components/Footer";
import SocialSection from "./Sections/SocialSection";

const HomePage = () => {
  return (
    <div className={styles.mainContainerStyle}>
      <div className={styles.mainInsideContainerStyle}>
        <Navbar />
      </div>
      <div className={styles.outsideTopHeaderContainerStyle}>
        {HeroSection()}
      </div>
      <div className={styles.outsideReturnContainerStyle}>
        {ReturnSection()}
      </div>
      <div className={styles.outsideSoSImpleContainerStyle}>
        {HowItWorksSection()}
      </div>
      <div className={styles.outsideSustainabilityContainerStyle}>
        {/* {SustainabilitySection()} */}
      </div>
      {SocialSection()}
      {Footer()}
    </div>
  );
};

export default HomePage;

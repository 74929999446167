import React, { useState } from "react";
import styles from "./styles.module.css";
import {
  darkCloseIcon,
  whiteCheckIcon,
  product1Img,
  product2Img,
  profileHistoryIcon,
} from "../../resources/Images/images";
import Button from "../../components/Button";
import ModalPopUp from "../../components/ModalBox";
import { Rating } from "react-simple-star-rating";
import { HiStar } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

const OrderHistoryPage = () => {
  const navigate = useNavigate();
  const [openReview, setOpenreview] = useState(false);
  const [openTracking, setOpenTracking] = useState(false);
  const [ratingValue, setRatingValue] = useState(0);

  const handleRating = (rate) => {
    setRatingValue(rate);
  };
  const handleCloseReview = () => {
    setOpenreview(false);
  };
  const handleCloseTracking = () => {
    setOpenTracking(false);
  };
  const customIcons = [
    {
      icon: <HiStar size={32} />,
    },
    {
      icon: <HiStar size={32} />,
    },
    {
      icon: <HiStar size={32} />,
    },
    {
      icon: <HiStar size={32} />,
    },
    {
      icon: <HiStar size={32} />,
    },
  ];
  const fillColorArray = ["#FFC700"];
  const renderTopNavContainer = () => {
    return (
      <div className={styles.navContainerStyle}>
        <p className={styles.navHeaderTextStyle}>Order history</p>
        <div
          className={styles.closeIconWrapperStyle}
          onClick={() => navigate(-1)}
        >
          <img src={darkCloseIcon} className={styles.imgStyle} />
        </div>
      </div>
    );
  };
  const renderRecentlyOrderedHistory = () => {
    return (
      <div className={styles.recentOrdersContainerStyle}>
        <div className={styles.orderDetailsContainerStyle}>
          <div className={styles.orderDetailsWrapperStyle}>
            <p className={styles.orderNoTextStyle}>Order No. 11001122</p>
            <div className={styles.orderDeliveryTimeContainerStyle}>
              <p className={styles.orderedDateLabelTextStyle}>
                Odered on:&nbsp;
                <span className={styles.orderedDateTextStyle}>23-06-2022</span>
              </p>
              <p className={styles.orderDeliveryTimeLabelStyle}>
                Estimated delivery:
                <span className={styles.orderDeliveryTimeTextStyle}>
                  By tomorrow 9 PM
                </span>
              </p>
            </div>
          </div>
          <div className={styles.orderDetailsRightContainerStyle}>
            <p className={styles.orderPriceTextStyle}>$180.00</p>
            <p className={styles.orderredInvoiceTextStyle}>Invoice</p>
          </div>
        </div>
        <div className={styles.borderStyle}></div>
        <div className={styles.productsMainContainerStyle}>
          <div className={styles.noOfProductsContainerStyle}>
            <div className={styles.orderedProductDetailsContainerStyle}>
              <div className={styles.productImgWrapperstyle}>
                <img src={product2Img} className={styles.imgStyle} />
              </div>
              <div className={styles.orderedProductContentWrapperStyle}>
                <div className={styles.productPriceContainerStyle}>
                  <p className={styles.productTagsTextStyle}>
                    Keychain tags - A pack of 4
                  </p>
                  <p className={styles.productPriceTextStyle}>
                    Price (x1): $ 90.00
                  </p>
                </div>
                <div>
                  <Button
                    title={"Write a review"}
                    btnStyles={styles.reviewBtn1Styles}
                    onClick={() => setOpenreview(true)}
                  />
                </div>
              </div>
            </div>
            <Button
              title={"Write a review"}
              btnStyles={styles.reviewBtn2Styles}
              onClick={() => setOpenreview(true)}
            />
            <div className={styles.orderedProductDetailsContainerStyle}>
              <div className={styles.productImgWrapperstyle}>
                <img src={product2Img} className={styles.imgStyle} />
              </div>
              <div className={styles.orderedProductContentWrapperStyle}>
                <div className={styles.productPriceContainerStyle}>
                  <p className={styles.productTagsTextStyle}>
                    Keychain tags - A pack of 4
                  </p>
                  <p className={styles.productPriceTextStyle}>
                    Price (x1): $ 90.00
                  </p>
                </div>
                <div>
                  <Button
                    title={"Write a review"}
                    btnStyles={styles.reviewBtn1Styles}
                    onClick={() => setOpenreview(true)}
                  />
                </div>
              </div>
            </div>
            <Button
              title={"Write a review"}
              btnStyles={styles.reviewBtn2Styles}
              onClick={() => setOpenreview(true)}
            />
          </div>
          <div className={styles.btnContainerStyles}>
            <Button
              title={"Track order"}
              btnStyles={styles.trackBtnStyles}
              onClick={() => setOpenTracking(true)}
            />
            <Button
              title={"Cancel order"}
              btnStyles={styles.cancelBtnStyles}
              onClick={() => console.log("555")}
            />
          </div>
        </div>
      </div>
    );
  };
  const oldOrderedHistory = () => {
    return (
      <div className={styles.recentOrdersContainerStyle}>
        <div className={styles.orderDetailsContainerStyle}>
          <div className={styles.orderDetailsWrapperStyle}>
            <p className={styles.orderNoTextStyle}>Order No. 11001122</p>
            <div className={styles.orderDeliveryTimeContainerStyle}>
              <p className={styles.orderedDateLabelTextStyle}>
                Odered on:&nbsp;
                <span className={styles.orderedDateTextStyle}>23-06-2022</span>
              </p>
              <p className={styles.orderDeliveryTimeLabelStyle}>
                Estimated delivery:
                <span className={styles.orderDeliveryTimeTextStyle}>
                  By tomorrow 9 PM
                </span>
              </p>
            </div>
          </div>
          <div className={styles.orderDetailsRightContainerStyle}>
            <p className={styles.orderPriceTextStyle}>$180.00</p>
            <p className={styles.orderredInvoiceTextStyle}>Invoice</p>
          </div>
        </div>
        <div className={styles.borderStyle}></div>
        <div className={styles.productsMainContainerStyle}>
          <div className={styles.noOfProductsContainerStyle}>
            <div className={styles.orderedProductDetailsContainerStyle}>
              <div className={styles.productImgWrapperstyle}>
                <img src={product2Img} className={styles.imgStyle} />
              </div>
              <div className={styles.orderedProductContentWrapperStyle}>
                <div className={styles.productPriceContainerStyle}>
                  <p className={styles.productTagsTextStyle}>
                    Keychain tags - A pack of 4
                  </p>
                  <p className={styles.productPriceTextStyle}>
                    Price (x1): $ 90.00
                  </p>
                </div>
                <div>
                  <Button
                    title={"Write a review"}
                    btnStyles={styles.reviewBtn1Styles}
                    onClick={() => setOpenreview(true)}
                  />
                </div>
              </div>
            </div>
            <Button
              title={"Write a review"}
              btnStyles={styles.reviewBtn2Styles}
              onClick={() => setOpenreview(true)}
            />
          </div>
          <div className={styles.btnContainerStyles}>
            <Button
              title={"Buy again"}
              btnStyles={styles.cancelBtnStyles}
              onClick={() => console.log("555")}
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={styles.orderMainContainerStyle}>
      <div className={styles.orderMainInsideContainerStyle}>
        {renderTopNavContainer()}
        <div className={styles.orderedHistoryDetailsStyle}>
          {renderRecentlyOrderedHistory()}
          {oldOrderedHistory()}
          {oldOrderedHistory()}
        </div>
      </div>
      <ModalPopUp open={openReview} handleClose={handleCloseReview}>
        <div className={styles.reviewModalContainerStyle}>
          <div className={styles.reviewModalTopContainerStyle}>
            <div className={styles.modalImgWrapperStyles}>
              <img src={product2Img} className={styles.imgStyle} />
            </div>
          </div>
          <div className={styles.reviewModalBottomContainerStyle}>
            <div className={styles.reviewRatingContainerStyle}>
              <p className={styles.reviewModalHeaderTextStyle}>
                Tell us how was your experience with our product?
              </p>
              <Rating
                onClick={handleRating}
                customIcons={customIcons}
                initialValue={ratingValue}
                transition
                allowFraction
                fillColorArray={fillColorArray}
              />
            </div>
            <div>
              <textarea
                placeholder="Type your comments here . . ."
                className={styles.textareaStyle}
              />
            </div>
            <div className={styles.reviewModalBtnContainerStyle}>
              <Button
                title={"Submit"}
                btnStyles={styles.modalSubmitBtnStyles}
                onClick={() => console.log("555")}
              />
              <Button
                title={"Remind later"}
                btnStyles={styles.modalRemindLaterBtnStyles}
                onClick={() => console.log("555")}
              />
            </div>
          </div>
        </div>
      </ModalPopUp>
      <ModalPopUp open={openTracking} handleClose={handleCloseTracking}>
        <div className={styles.trackingModalContainerStyle}>
          <div className={styles.trackingModalHeaderContainerStyle}>
            <p className={styles.trackingModalHeaderTextStyle}>Track order</p>
            <div
              className={styles.closeIconWrapperStyle}
              onClick={() => setOpenTracking(false)}
            >
              <img src={darkCloseIcon} className={styles.imgStyle} />
            </div>
          </div>
          <div className={styles.trackingModalEsitmContainerStyle}>
            <p className={styles.trackingModalEstimTextStyle}>
              You order is estimated to be delivered by tomorrow 9 PM
            </p>
          </div>
          <div className={styles.trackingModalListContainerStyle}>
            <div className={styles.checkIconMainWrapperStyle}>
              <div className={styles.checkIconWrapperStyle}>
                <img src={whiteCheckIcon} className={styles.imgStyle} />
              </div>
            </div>
            <div className={styles.trackDataTextWrapperStyle}>
              <p className={styles.trackDataTextHeaderStyle}>
                Pick-up request accepted
              </p>
              <div className={styles.trackingModalTimeContainerStyle}>
                <div className={styles.closeIconWrapperStyle}>
                  <img src={profileHistoryIcon} className={styles.imgStyle} />
                </div>

                <p className={styles.trackDataTextTimeStyle}>
                  at 9:10 pm, 04-05-2022 - Place name here
                </p>
              </div>
            </div>
          </div>
        </div>
      </ModalPopUp>
    </div>
  );
};

export default OrderHistoryPage;

import React from "react";
import styles from "./styles.module.css";
import Navbar from "../../components/NavBar";
import { securityImg } from "../../resources/Images/images";
import Footer from "../../components/Footer";

const PrivacyPolicyPage = () => {
  const topDisplayHonestySection = () => {
    return (
      <div className={styles.privacyHeroSectionContainerStyle}>
        <div className={styles.securityImgOuterWrapperStyle}>
          <div className={styles.securityImgWrapperStyle}>
            <img src={securityImg} className={styles.imgStyle} />
          </div>
        </div>
        <div className={styles.privacyContentContainerStyle}>
          <p className={styles.privacyHeaderTextStyle}>
            At Foundi, honesty goes first.
          </p>
          <div className={styles.privacyDescTextContainerStyle}>
            <p className={styles.privacyDescTextStyle}>
              We want to inspire honesty, and that starts with Foundi being
              transparent with how we handle our customer's privacy and
              security.
            </p>
            <p className={styles.privacyDescTextStyle}>
              One way we are keeping your security a top priority is with our
              integrated chat function which allows you to choose what
              information you want to share with others. Your identification
              marker is the unique SNO-code present on your Foundi products,
              everything else is up to you to share.
            </p>
            <p className={styles.privacyDescTextStyle}>
              We value the information you chose to share with us, giving us the
              ability to make your experience as personal as possible. However,
              with trust comes responsibility, which means we never sell your
              data or information to other parties.
            </p>
            <p className={styles.privacyDescTextStyle}>
              Our mission is to make you feel safe in your day-to-day
              activities. Your safety and privacy should not be a matter of
              concern when you are using Foundi. If you want to read more about
              Foundi's honesty policy, see below.
            </p>
          </div>
        </div>
      </div>
    );
  };
  const secondSectionPrivacyPolicy = () => {
    return (
      <div className={styles.secondSectionContainerStyle}>
        <div className={styles.privacyPolicyHeaderSectionStyle}>
          <p className={styles.privacyPolicyHeaderTextStyle}>Privacy policy</p>
          <p className={styles.lastUpdatedTextStyle}>
            Last updated:&nbsp;
            <span className={styles.lastUpdatedDateTextStyle}>
              Dec 30th 2022,&nbsp;
            </span>
            Last reviewed:&nbsp;
            <span className={styles.lastUpdatedDateTextStyle}>
              Dec 20th 2022
            </span>
          </p>
        </div>
        <div className={styles.privacySecondSectionContentContainerStyle}>
          <p className={styles.secondSectionHeaderTextStyle}>Data controller</p>
          <div className={styles.privacyDescTextContainerStyle}>
            <p className={styles.privacyDescTextStyle}>
              Vilje Tech AB (“Vilje Tech”, “we”, “us”, “our”), the developer of
              the Foundi app (“Foundi”), is a data controller of personal data
              (“Data”). At Vilje Tech, we take your privacy very seriously and
              ensure that all applicable data privacy regulations are observed.
              In this section, you can learn about how we process your Data when
              you use:
            </p>
            <div>
              <p className={styles.privacyDescTextStyle}>
                -
                <span className={styles.anchorLinkStyle}>
                  www.foundiapp.com
                </span>
                (“Site”),
              </p>
              <p className={styles.privacyDescTextStyle}>
                - the Foundi web or mobile app (“App”, “Apps”), or
              </p>
              <p className={styles.privacyDescTextStyle}>
                - corresponding products and services offered by Vilje Tech
                (“Service”, “Services”, “Solutions”).
              </p>
            </div>
            <p className={styles.privacyDescTextStyle}>
              This Policy does not apply to third-party applications, websites,
              products, services, or platforms that may be accessed through
              links that we provide to you. These third parties are owned and
              operated independently from us and have their own separate privacy
              and data-collection practices. Any personal data that you provide
              to these third parties will be governed by the third party’s own
              privacy policy. We cannot accept liability for the actions or
              policies of these independent third parties, and we are not
              responsible for the content or privacy practices of such sites.
            </p>
          </div>
        </div>
        <div className={styles.privacySecondSectionContentContainerStyle}>
          <p className={styles.secondSectionHeaderTextStyle}>About Foundi</p>
          <div className={styles.privacyDescTextContainerStyle}>
            <p className={styles.privacyDescTextStyle}>
              Foundi is a QR code solution built to help recover your lost items
              while protecting your identity and privacy. You can tag all your
              belongings by registering your products in our App by scanning the
              QR code or entering the serial number (SNO). While registering the
              product, you can select a gift card to reward the finder of your
              lost item.
            </p>
            <p className={styles.privacyDescTextStyle}>
              Foundi products come in multiple variants, ranging from simple
              keychains to laptop stickers, so that all of your items can be
              tagged with ease. In case you lose your belongings, the finder can
              easily scan the QR code or enter the SNO and be in touch with you
              to return the item.
            </p>
          </div>
        </div>
        <div className={styles.privacySecondSectionContentContainerStyle}>
          <p className={styles.secondSectionHeaderTextStyle}>
            Categories of personal data
          </p>
          <div className={styles.privacyDescTextContainerStyle}>
            <p className={styles.privacyDescTextStyle}>
              Depending on your interaction with Vilje Tech, we may process
              different categories of your Data. When using our Site or Apps,
              the categories of Data that we may process include:
            </p>
            <div>
              <p className={styles.privacyDescTextStyle}>- Name</p>
              <p className={styles.privacyDescTextStyle}>- Postal address</p>
              <p className={styles.privacyDescTextStyle}>- Email address</p>
              <p className={styles.privacyDescTextStyle}>
                - Submitted video data
              </p>
              <p className={styles.privacyDescTextStyle}>
                - Device-specific data (e.g. hardware model, operating system,
                and crash data)
              </p>
              <p className={styles.privacyDescTextStyle}>- Telephone number</p>
              <p className={styles.privacyDescTextStyle}>
                - Feedback, support, and error submissions
              </p>
              <p className={styles.privacyDescTextStyle}>
                - Cookie and marketing preferences
              </p>
              <p className={styles.privacyDescTextStyle}>
                - Payment information
              </p>
            </div>
            <p className={styles.privacyDescTextStyle}>
              Additionally, when submitting feedback, support, or error forms,
              you may be asked to provide your name, contact information,
              feedback or error message, and the identification number of the
              product in question.
            </p>

            <p className={styles.privacyDescTextStyle}>
              This Data is collected when you register or use our Site, Apps, or
              Solutions, opt-in to cookies, opt-in to receiving marketing
              material, submit feedback or booking forms, from public records,
              and through direct correspondence between Vilje Tech and yourself.
            </p>
          </div>
        </div>
        <div className={styles.privacySecondSectionContentContainerStyle}>
          <p className={styles.secondSectionHeaderTextStyle}>Purpose</p>
          <div className={styles.privacyDescTextContainerStyle}>
            <p className={styles.privacyDescTextStyle}>
              Vilje Tech processes your Data in order to be able to provide to
              you in completion our services in accordance with your wishes, to
              improve and expand our Solutions and Apps, for insight and
              research purposes, to communicate with you, to screen for
              potential risk or fraud, and, when in line with the preferences
              that you have indicated, to provide you with advertisement or
              promotional material.
            </p>
            <p className={styles.privacyDescTextStyle}>
              As we develop insights and research, we ensure that data is
              aggregated in order to prevent any individual from being
              identified, in effect anonymising the data set. Our legal bases
              for processing are:
            </p>
            <div>
              <p className={styles.privacyDescTextStyle}>
                - Based on your consent,
              </p>
              <p className={styles.privacyDescTextStyle}>
                - Necessary for the performance of contract,
              </p>
              <p className={styles.privacyDescTextStyle}>
                - Due to other legal obligations, or
              </p>
              <p className={styles.privacyDescTextStyle}>
                - Legitimate interest.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.privacySecondSectionContentContainerStyle}>
          <p className={styles.secondSectionHeaderTextStyle}>Data retention</p>
          <div className={styles.privacyDescTextContainerStyle}>
            <p className={styles.privacyDescTextStyle}>
              Unless required by law, Data is retained for up to 24 months after
              your last interaction with Vilje Tech, after which it is erased or
              anonymised. Interaction with Vilje Tech includes, for example,
              using our Site, Apps, or Services, or opening marketing
              communications from us.
            </p>
            <p className={styles.privacyDescTextStyle}>
              You can at any time opt-out of receiving marketing communications
              or close your account by following on-screen instructions, or by
              contacting us at
              <span className={styles.anchorLinkStyle}>
                privacy@foundiapp.com
              </span>
            </p>
          </div>
        </div>
        <div className={styles.privacySecondSectionContentContainerStyle}>
          <p className={styles.secondSectionHeaderTextStyle}>
            Employment applications
          </p>
          <div className={styles.privacyDescTextContainerStyle}>
            <p className={styles.privacyDescTextStyle}>
              For both published vacancies and open applications, our
              recruitment process may involve the processing of the following
              categories of Data of our applicants: name; postal address; email
              address; telephone number; government-issued identification;
              references and CVs. This Data is retained for up to 5 years after
              the application date, or for as long as we have a legitimate
              business need for it. This Data is collected from public records,
              third-party recommendations and referrals, and through direct
              correspondence between Vilje Tech and yourself.
            </p>
          </div>
        </div>
        <div className={styles.privacySecondSectionContentContainerStyle}>
          <p className={styles.secondSectionHeaderTextStyle}>
            Third parties and Countries
          </p>
          <div className={styles.privacyDescTextContainerStyle}>
            <p className={styles.privacyDescTextStyle}>
              We may share your Data with affiliated companies with which we
              have partnered in order to provide to you a greater set of
              services, and with trusted third-party service providers as
              necessary for them to perform services on our behalf, such as:
            </p>
            <div>
              <p className={styles.privacyDescTextStyle}>
                - Cloud data storage
              </p>
              <p className={styles.privacyDescTextStyle}>
                - Processing payments
              </p>
              <p className={styles.privacyDescTextStyle}>
                - Product development
              </p>
              <p className={styles.privacyDescTextStyle}>
                - Application deployment and publication
              </p>
              <p className={styles.privacyDescTextStyle}>- Cybersecurity</p>
              <p className={styles.privacyDescTextStyle}>- Market research</p>
              <p className={styles.privacyDescTextStyle}>
                - Serving advertisements and conducting surveys
              </p>
              <p className={styles.privacyDescTextStyle}>
                - Communicating with you, such as by way of email or survey
                delivery
              </p>
              <p className={styles.privacyDescTextStyle}>- Legal consultancy</p>
              <p className={styles.privacyDescTextStyle}>
                - Customer relationship management, including social media
              </p>
              <p className={styles.privacyDescTextStyle}>
                - Support and related services
              </p>
            </div>
            <p className={styles.privacyDescTextStyle}>
              As often as is appropriate and practical, third parties are only
              provided with aggregated data (through which individuals cannot be
              identified) and are obligated not to disclose or use the
              information for any purpose other than those listed above.
            </p>
            <p className={styles.privacyDescTextStyle}>
              Vilje Tech uses servers located within the EU; however, if we
              transfer Data outside of the EU, we check that the third country
              meets the requirements of applicable data privacy legislation and
              make the necessary contractual arrangements to ensure third-party
              compliance. Vilje Tech does not sell your personal data.
            </p>
            <p className={styles.privacyDescTextStyle}>
              For a full list of our third parties, please send your request to
              <span className={styles.anchorLinkStyle}>
                {" "}
                privacy@foundiapp.com
              </span>
            </p>
          </div>
        </div>
        <div className={styles.privacySecondSectionContentContainerStyle}>
          <p className={styles.secondSectionHeaderTextStyle}>Cookies</p>
          <div className={styles.privacyDescTextContainerStyle}>
            <p className={styles.privacyDescTextStyle}>
              A cookie is a small text file that the website or application that
              you are visiting requests to save on your device. They are widely
              used in order to make websites and applications work, or work more
              efficiently, as well as to provide information to the owners of
              the site or app.
            </p>
            <p className={styles.privacyDescTextStyle}>
              Vilje Tech only uses essential cookies, those that allow our Site
              and Apps to work – as such, these cannot be disabled.
            </p>
            <p className={styles.privacyDescTextStyle}>
              Should Vilje Tech ever update its cookie collection for other
              processing purposes, we will first request your consent.
            </p>
            <p className={styles.privacyDescTextStyle}>
              PTS, the regulatory authority in the field, provides additional
              information about cookies on its website, pts.se
            </p>
            <p className={styles.privacyDescTextStyle}>
              For information on how to manage your browser’s cookie settings,
              follow these links:
            </p>
            <div>
              <p className={styles.privacyDescTextStyle}>
                - <span className={styles.browserTextStyle}>Chrome</span>
              </p>
              <p className={styles.privacyDescTextStyle}>
                -
                <span className={styles.browserTextStyle}>
                  Internet Explorer
                </span>
              </p>
              <p className={styles.privacyDescTextStyle}>
                - <span className={styles.browserTextStyle}>Firefox</span>
              </p>
              <p className={styles.privacyDescTextStyle}>
                - <span className={styles.browserTextStyle}>Safari</span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.privacySecondSectionContentContainerStyle}>
          <p className={styles.secondSectionHeaderTextStyle}>Cybersecurity</p>
          <div className={styles.privacyDescTextContainerStyle}>
            <p className={styles.privacyDescTextStyle}>
              Vilje Tech protects your Data using technical and organisational
              security measures. In order to prevent unauthorised access and
              ensure data accuracy, we have implemented strict guidelines for
              the organisation regarding the processing of personal data,
              including the use of two-factor authentication (2FA), SSL
              encryption, and trusted third-party cloud data storage, with
              servers located within the EU.
            </p>
          </div>
        </div>
        <div className={styles.privacySecondSectionContentContainerStyle}>
          <p className={styles.secondSectionHeaderTextStyle}>Your rights</p>
          <div className={styles.privacyDescTextContainerStyle}>
            <p className={styles.privacyDescTextStyle}>
              Under the EU General Data Protection Regulation (EU GDPR), you
              have the right to transparency in how your Data is processed. This
              means that you can at any time:
            </p>
            <div>
              <p className={styles.privacyDescTextStyle}>
                - Request an extract of what Data we process about you,
              </p>
              <p className={styles.privacyDescTextStyle}>
                - Request that we correct any Data that is incorrect,
              </p>
              <p className={styles.privacyDescTextStyle}>
                - Request that we erase your Data,
              </p>
              <p className={styles.privacyDescTextStyle}>
                - Request that we limit how your Data is used,
              </p>
              <p className={styles.privacyDescTextStyle}>
                - Object to the processing of your Data that we deem legitimate,
              </p>
              <p className={styles.privacyDescTextStyle}>
                - Request to have your Data transferred from Vilje Tech’s system
                to another body, i.e. data portability, and
              </p>
              <p className={styles.privacyDescTextStyle}>
                - Revoke previously granted consent to any of our personal data
                processes.
              </p>
            </div>
            <p className={styles.privacyDescTextStyle}>
              If you would like to request any of the above, please contact us
              at
              <span className={styles.anchorLinkStyle}>
                privacy@foundiapp.com
              </span>
            </p>
            <p className={styles.privacyDescTextStyle}>
              You are entitled to contact Sweden’s data protection authority,
              Integritetsskyddsmyndigheten (IMY), to file a complaint if you are
              dissatisfied with how we process your Data.
            </p>
            <p className={styles.privacyDescTextStyle}>
              More information about the EU GDPR can be found at
              <span className={styles.anchorLinkStyle}> imy.se</span> and
              <span className={styles.anchorLinkStyle}>ec.europa.eu</span>
            </p>
          </div>
        </div>
        <div className={styles.privacySecondSectionContentContainerStyle}>
          <p className={styles.secondSectionHeaderTextStyle}>
            Changes to Vilje Tech’s privacy policy
          </p>
          <div className={styles.privacyDescTextContainerStyle}>
            <p className={styles.privacyDescTextStyle}>
              We may periodically update this Policy in order to accurately
              reflect changes to operations, or for regulatory reasons. When
              this happens, we will inform you by reasonable correspondence -
              for example, by email, through our Apps, or on our Site.
            </p>
          </div>
        </div>
        <div className={styles.privacySecondSectionContentContainerStyle}>
          <p className={styles.secondSectionHeaderTextStyle}>
            Contact information
          </p>
          <div className={styles.privacyDescTextContainerStyle}>
            <p className={styles.privacyDescTextStyle}>
              If you have questions about how Vilje Tech processes your personal
              data, or the contents of this Policy, you can at any time email
              <span className={styles.anchorLinkStyle}>
                privacy@foundiapp.com
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Navbar navWhite={true} />
      <div className={styles.privacyPolicyMainContainerStyle}>
        {topDisplayHonestySection()}
        {secondSectionPrivacyPolicy()}
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicyPage;

import React from "react";
import styles from "./styles.module.css";
import { product1Img, product2Img } from "../../../../resources/Images/images";
import { useNavigate } from "react-router-dom";
const CustomerLikedProductsSection = () => {
  const navigate = useNavigate();
  const Products = [
    {
      id: 1,
      image: product1Img,
      name: "Regular tags - A pack of 4",
      discountPrice: "$59.00 ",
      originalPrice: "$99.00",
    },
    {
      id: 2,
      image: product2Img,
      name: "Regular tags - A pack of 4",
      discountPrice: "$59.00 ",
      originalPrice: "$99.00",
    },
    {
      id: 3,
      image: product1Img,
      name: "Regular tags - A pack of 4",
      discountPrice: "$59.00 ",
      originalPrice: "$99.00",
    },
  ];
  return (
    <div className={styles.customerLikedSectionContainerStyle}>
      <p className={styles.headerTextStyle}> Customers also liked</p>
      <div className={styles.productsListContainerStyle}>
        {Products.map((item, index) => {
          return (
            <div
              key={"products" + index}
              className={styles.productWrapperStyle}
              onClick={() => navigate("/productdetails")}
            >
              <div className={styles.imgWrapperStyle}>
                <img src={item.image} className={styles.imgStyle} />
              </div>
              <p className={styles.productNameTextStyle}>{item.name}</p>
              <p className={styles.productDiscountPriceTextStyle}>
                {item.discountPrice}
                <span className={styles.productOriginalPriceTextStyle}>
                  {item.originalPrice}
                </span>
              </p>
            </div>
          );
        })}
      </div>
      <p className={styles.browseAllProductsTextStyle}>
        Browse all products 🛍️
      </p>
    </div>
  );
};

export default CustomerLikedProductsSection;

import React from "react";
import styles from "./styles.module.css";
import {
  errorIcon,
  pwViewIcon,
  pwHideIcon,
} from "../../resources/Images/images";

const TextInput = (props) => {
  return (
    <>
      {props.textInput && (
        <div className={styles.inputMainContainerStyle}>
          {props.labelName && (
            <label className={styles.labelNameStyle}>{props.labelName}</label>
          )}
          <div className={styles.inputContainerStyle}>
            {props.chatInputIcon && (
              <div className={styles.chatWrapperStyle} onClick={props.onClick}>
                <img src={props.chatInputIcon} className={styles.imgStyle} />
              </div>
            )}
            <input
              className={[
                props.inputStyles,
                props.error ? styles.errorInputStyles : styles.inputStyle,
              ].join(" ")}
              type={props.type}
              placeholder={props.placeholderName}
              value={props.inputValue}
              onChange={(text) => props.onChangeText(text)}
            />
            {props.rightIcon && (
              <div className={styles.arrowWrapperStyle}>
                <img
                  src={props.error ? errorIcon : props.rightIcon}
                  className={styles.imgStyle}
                />
              </div>
            )}
            {props.passWord && (
              <div className={styles.arrowWrapperStyle} onClick={props.onClick}>
                <img
                  src={props.visible ? pwViewIcon : pwHideIcon}
                  className={styles.imgStyle}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {props.phoneNumber && (
        <div className={styles.inputMainContainerStyle}>
          {props.labelName && (
            <label className={styles.labelNameStyle}>{props.labelName}</label>
          )}
          <div className={styles.phonNoContainerStyle}>
            <p className={styles.countryCodeTextStyle}>{props.countryCode}</p>
            <div className={styles.borderStyle}></div>
            <input
              className={styles.phoneNoInputStyle}
              type={props.type}
              placeholder={props.placeholderName}
              value={props.inputValue}
              onChange={(text) => props.onChangeText(text)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default TextInput;

import React, { useState } from "react";
import styles from "./styles.module.css";
import {
  downArrowIcon,
  upArrowIcon,
  product2Img,
  product1Img,
  googlePlayStoreImg,
  appleAppStoreImg,
  recoverTag,
  connectTag,
  addTag,
  purchaseTag,
} from "../../../../resources/Images/images";

const HowItWorksSection = () => {
  const [toggleIndex, setToggleIndex] = useState(null);
  const setToggle = (index) => {
    if (index === toggleIndex) {
      setToggleIndex(null);
    } else {
      setToggleIndex(index);
    }
  };
  const Questions = [
    {
      id: 1,
      que: "01 - What should I do if I found an item without Foundi tag attached to it?",
      ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse risus urna, rutrum non quam in. Lorem ipsum dolor sit. Suspendisse risus urna, rutrum non quam in. Lorem ipsum dolor sit. Suspendisse risus urna, rutrum non quam in.",
    },
    {
      id: 2,
      que: "02 - Here goes the question number two?",
      ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse risus urna, rutrum non quam in. Lorem ipsum dolor sit. Suspendisse risus urna, rutrum non quam in. Lorem ipsum dolor sit. Suspendisse risus urna, rutrum non quam in.",
    },
    {
      id: 3,
      que: "03 - Here goes the question number three?",
      ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse risus urna, rutrum non quam in. Lorem ipsum dolor sit. Suspendisse risus urna, rutrum non quam in. Lorem ipsum dolor sit. Suspendisse risus urna, rutrum non quam in.",
    },
    {
      id: 4,
      que: "04 - Here goes the question number four and how the questions should look if it take two or more lines?",
      ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse risus urna, rutrum non quam in. Lorem ipsum dolor sit. Suspendisse risus urna, rutrum non quam in. Lorem ipsum dolor sit. Suspendisse risus urna, rutrum non quam in.",
    },
  ];
  const products = [
    {
      id: 1,
      image: product1Img,
      name: "Regular tags - A pack of 4",
      price: "$99.00",
    },
    {
      id: 2,
      image: product2Img,
      name: "Regular tags - A pack of 4",
      price: "$29.00",
    },
    {
      id: 1,
      image: product1Img,
      name: "Regular tags - A pack of 1",
      price: "$99.00",
    },
  ];

  const renderSimpleTextContainer = () => {
    return (
      <div className={styles.simpleHeaderContainerStyle}>
        <p className={styles.simpleHeaderTextStyle}>Ah, it’s so simple!</p>
        <p className={styles.simpleDescriptionTextStyle}>
          Still unsure how Foundi works? Don’t worry, these 4 simple steps will
          get you up to speed.
        </p>
      </div>
    );
  };
  const renderfoundiFlowContainer = () => {
    return (
      <div className={styles.flowContainerStyle}>
        <div className={styles.flowSection1Style}>
          <div className={styles.foundiFlowContentWrapperStyle}>
            <p className={styles.foundiFlowtitleTextStyle}>
              01 - Purchase tags
            </p>
            <p className={styles.foundiFlowDescTextStyle}>
              Purchase physical Foundi tags from our app or the website. These
              tags will allow you to register your belongings within our
              database.
            </p>
          </div>
          <div className={styles.imgWrapperStyle}>
            <img src={purchaseTag} className={styles.imgStyle} />
          </div>
        </div>
        <div className={styles.flowSection2Style}>
          <div className={styles.imgWrapperStyle}>
            <img src={addTag} className={styles.imgStyle} />
          </div>
          <div className={styles.foundiFlowContentWrapperStyle}>
            <p className={styles.foundiFlowtitleTextStyle}>02 - Add items</p>
            <p className={styles.foundiFlowDescTextStyle}>
              Attach the purchased Foundi-tags to your belongings & scan the QR
              code on it to register them in our database.
            </p>
          </div>
        </div>
        <div className={styles.flowSection1Style}>
          <div className={styles.foundiFlowContentWrapperStyle}>
            <p className={styles.foundiFlowtitleTextStyle}>03 - Connect</p>
            <p className={styles.foundiFlowDescTextStyle}>
              If you lost your registered item, finder(s) can scan the QR (or
              enter SNO) present on the tag and can reach you anonymously via
              our inbuilt messenger.
            </p>
          </div>
          <div className={styles.imgWrapperStyle}>
            <img src={connectTag} className={styles.imgStyle} />
          </div>
        </div>
        <div className={styles.flowSection2Style}>
          <div className={styles.imgWrapperStyle}>
            <img src={recoverTag} className={styles.imgStyle} />
          </div>
          <div className={styles.foundiFlowContentWrapperStyle}>
            <p className={styles.foundiFlowtitleTextStyle}>04 - Recover</p>
            <p className={styles.foundiFlowDescTextStyle}>
              Conversing with the finder, figure out a way to recover your
              misplaced item. That’s it! We told you, it is simple.
            </p>
          </div>
        </div>
      </div>
    );
  };
  const renderPlayStoreContainer = () => {
    return (
      <div className={styles.playStoreContainerStyle}>
        <div className={styles.appStoreWrapperStyle}>
          <img src={appleAppStoreImg} className={styles.imgStyle} />
        </div>
        <div className={styles.appStoreWrapperStyle}>
          <img src={googlePlayStoreImg} className={styles.imgStyle} />
        </div>
      </div>
    );
  };
  const renderFeatureProductsContainer = () => {
    return (
      <div className={styles.simpleHeaderContainerStyle}>
        <p className={styles.simpleHeaderTextStyle}>Featured products</p>
        <p className={styles.simpleDescriptionTextStyle}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          risus urna, rutrum non quam in. Lorem ipsum dolor sit.
        </p>
      </div>
    );
  };
  const renderProductsContainer = () => {
    return (
      <div>
        <div className={styles.productsMainContainerStyle}>
          {products.map((item, index) => {
            return (
              <div key={index} className={styles.productContainerStyle}>
                <div className={styles.productImgWrapperStyle}>
                  <img src={item.image} className={styles.imgStyle} />
                </div>
                <p className={styles.productNameTextStyle}>{item.name}</p>
                <p className={styles.productPriceStyle}>{item.price}</p>
              </div>
            );
          })}
        </div>
        <a className={styles.browseAllProductsAnchorStyle}>
          <p className={styles.browseAllProductsStyle}>Browse all products</p>
          <span>🛍️</span>
        </a>
      </div>
    );
  };
  const renderQuestionsHeaderContainer = () => {
    return (
      <div className={styles.simpleHeaderContainerStyle}>
        <p className={styles.simpleHeaderTextStyle}>You’ve got questions?</p>
        <p className={styles.simpleHeaderTextStyle}>We’ve got answers!</p>
        <p className={styles.simpleDescriptionTextStyle}>
          Everything you need to know about our product. In case your query
          isn’t present below, please feel free to contact us.
        </p>
      </div>
    );
  };
  const renderQuestionsContainer = () => {
    return (
      <div>
        <div className={styles.questionsContainerStyle}>
          {Questions.map((item, index) => {
            return (
              <div key={index} className={styles.questionWrapperStyle}>
                <div className={styles.questionTextwrapperStyle}>
                  <p className={styles.questionTextStyle}>{item.que}</p>
                  <div
                    className={styles.arrowImgWrapperStyle}
                    onClick={() => setToggle(index)}
                  >
                    {toggleIndex === index ? (
                      <img src={upArrowIcon} className={styles.imgStyle} />
                    ) : (
                      <img src={downArrowIcon} className={styles.imgStyle} />
                    )}
                  </div>
                </div>
                {toggleIndex === index && (
                  <p className={styles.answerTextStyle}>{item.ans}</p>
                )}
              </div>
            );
          })}
        </div>
        <a className={styles.letsTalkWrapperStyle}>
          <p className={styles.letsTalkTextStyle}>Still have questions?</p>
          <p className={styles.browseAllProductsStyle}>Let’s talk</p>
          <span> 📞</span>
        </a>
      </div>
    );
  };

  return (
    <div className={styles.simpleContainerStyle}>
      {renderSimpleTextContainer()}
      {renderfoundiFlowContainer()}
      {renderPlayStoreContainer()}
      {/* {renderFeatureProductsContainer()} */}
      {/* {renderProductsContainer()} */}
      {renderQuestionsHeaderContainer()}
      {renderQuestionsContainer()}
    </div>
  );
};

export default HowItWorksSection;

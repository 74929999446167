import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Button from "../Button";
import { useNavigate, useLocation } from "react-router-dom";
import MenuPopover from "../Popover";
import {
  blueCheckIcon,
  blackCheckIcon,
  foundiLogo,
  languageIcon,
  cartIcon,
  profileIcon,
  menuOptionIcon,
  darkMenuOptionIcon,
  darkFoundiLogo,
  darkCartIcon,
  blackLanguageIcon,
  blackProfileIcon,
  darkCloseIcon,
  darkRightArrow,
  blueAddUserIcon,
  downArrowProfile,
  imageProfile,
  profileLogoutIcon,
  profileEditIcon,
  blueLangIcon,
  blackLangIcon,
  profileMessageIcon,
  profileHistoryIcon,
  darkLeftArrowIcon,
} from "../../resources/Images/images";

const Navbar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuBar, setMenuBar] = useState(false);
  const { width, height } = useWindowDimensions();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [viewProfile, setViewProfile] = useState(false);
  const [langView, setLangView] = useState(false);
  const divRefProfile = React.useRef();
  const divRefLanguage = React.useRef();
  const handleClickLang = (event) => {
    setAnchorE2(divRefLanguage.current);
  };
  const handleClick = (event) => {
    setAnchorEl(divRefProfile.current);
  };
  const handleCloseLanguage = () => {
    setAnchorE2(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const openLangPop = Boolean(anchorE2);
  const idPop = openLangPop ? "simple-popover" : undefined;
  useEffect(() => {
    if (width <= 867) {
      setMenuBar(menuBar);
      handleClose();
    } else {
      setMenuBar(false);
    }
  }, [width]);
  return (
    <div
      className={
        props.navWhite
          ? styles.navOutsideContainer2Style
          : styles.navOutsideContainerStyle
      }
    >
      <div className={styles.navInsideContainerStyle}>
        {menuBar ? (
          <div>
            <div className={styles.navWhiteContainerStyle}>
              <div
                className={styles.logoImgContainerStyle}
                onClick={() => {
                  navigate("/");
                }}
              >
                <img src={darkFoundiLogo} className={styles.imgStyle} />
              </div>
              <div className={styles.mobileNavMenuWrapperStyle}>
                <div className={styles.navMenuImgWrapperStyle}>
                  <img src={darkCartIcon} className={styles.imgStyle} />
                </div>
                <div
                  className={styles.navMenuImgWrapperStyle}
                  onClick={() => setMenuBar(!menuBar)}
                >
                  <img src={darkCloseIcon} className={styles.imgStyle} />
                </div>
              </div>
            </div>
            {viewProfile ? (
              <div className={styles.navMenuOptionsMobileContainerStyle}>
                <div className={styles.afterLoginTopContainerStyle}>
                  <div
                    className={styles.navMenuImgWrapperStyle}
                    onClick={() => setViewProfile(!viewProfile)}
                  >
                    <img src={darkLeftArrowIcon} className={styles.imgStyle} />
                  </div>
                  <div className={styles.profileImageStyle}>
                    <img src={imageProfile} className={styles.imgStyle} />
                  </div>
                  <div>
                    <p className={styles.profileNameTextStyle}>Hank Green</p>
                    <p className={styles.profileMailTextStyle}>
                      hankgreen@gmail.com
                    </p>
                  </div>
                </div>
                <div className={styles.afterLoginMenuContainerStyle}>
                  <div className={styles.afterLoginEditWrapperStyle}>
                    <div
                      className={styles.afterLoginEditProfileleftWrapperStyle}
                    >
                      <div className={styles.navMenuImgWrapperStyle}>
                        <img
                          src={profileEditIcon}
                          className={styles.imgStyle}
                        />
                      </div>
                      <p className={styles.afterLoginMenuTextStyle}>
                        Edit Profile
                      </p>
                    </div>
                    <div className={styles.navMenuImgWrapperStyle}>
                      <img src={darkRightArrow} className={styles.imgStyle} />
                    </div>
                  </div>
                  <div className={styles.afterLoginEditWrapperStyle}>
                    <div
                      className={styles.afterLoginEditProfileleftWrapperStyle}
                    >
                      <div className={styles.navMenuImgWrapperStyle}>
                        <img
                          src={profileMessageIcon}
                          className={styles.imgStyle}
                        />
                      </div>
                      <p className={styles.afterLoginMenuTextStyle}>
                        Messages (2)
                      </p>
                    </div>
                    <div className={styles.navMenuImgWrapperStyle}>
                      <img src={darkRightArrow} className={styles.imgStyle} />
                    </div>
                  </div>
                  <div className={styles.afterLoginEditWrapperStyle}>
                    <div
                      className={styles.afterLoginEditProfileleftWrapperStyle}
                    >
                      <div className={styles.navMenuImgWrapperStyle}>
                        <img
                          src={profileHistoryIcon}
                          className={styles.imgStyle}
                        />
                      </div>
                      <p className={styles.afterLoginMenuTextStyle}>
                        My Purchases
                      </p>
                    </div>
                    <div className={styles.navMenuImgWrapperStyle}>
                      <img src={darkRightArrow} className={styles.imgStyle} />
                    </div>
                  </div>
                </div>
                <div className={styles.afterLoginMenuMobileContainerStyle}>
                  <div className={styles.afterLoginEditProfileleftWrapperStyle}>
                    <div className={styles.navMenuImgWrapperStyle}>
                      <img
                        src={profileLogoutIcon}
                        className={styles.imgStyle}
                      />
                    </div>
                    <p className={styles.logoutTextStyle}>Logout</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.navMenuOptionsContainerStyle}>
                <div className={styles.profileMenuOptionWrapperStyle}>
                  <a className={styles.navMenuTextStyle}>Profile</a>
                  {/* <div
                    className={styles.navMenuImgWrapperStyle}
                    onClick={() => setViewProfile(!viewProfile)}
                  >
                    <img src={darkRightArrow} className={styles.imgStyle} />
                   
                  </div> */}
                  <Button
                    title="Log in"
                    btnStyles={styles.signUpBtnStyle}
                    onClick={() => navigate("/login")}
                  />
                </div>

                <a
                  onClick={() => {
                    navigate("/events");
                  }}
                  className={styles.navMenuTextStyle}
                >
                  Events & Business
                </a>
                <a
                  onClick={() => {
                    navigate("/about");
                  }}
                  className={styles.navMenuTextStyle}
                >
                  About
                </a>
                {/* <a
                  className={styles.navMenuTextStyle}
                  onClick={() => {
                    navigate("/products");
                  }}
                >
                  Products
                </a> */}
                {/* <div className={styles.profileMenuOptionWrapperStyle}>
                  <a className={styles.navMenuTextStyle}>Language</a>
                  <div
                    className={styles.navMenuImgWrapperStyle}
                    onClick={() => setLangView(!langView)}
                  >
                    <img
                      src={darkRightArrow}
                      className={
                        langView ? styles.reverseImgStyle : styles.imgStyle
                      }
                    />
                  </div>
                </div>
                {langView && (
                  <div className={styles.languagePopoverContainerStyle}>
                    <div className={styles.afterLoginEditWrapperStyle}>
                      <div
                        className={styles.afterLoginEditProfileleftWrapperStyle}
                      >
                        <div className={styles.navMenuImgWrapperStyle}>
                          <img src={blueLangIcon} className={styles.imgStyle} />
                        </div>
                        <p className={styles.afterLoginMenuTextStyle}>
                          English
                        </p>
                      </div>
                      <div className={styles.navMenuImgWrapperStyle}>
                        <img src={blueCheckIcon} className={styles.imgStyle} />
                      </div>
                    </div>
                    <div className={styles.afterLoginEditWrapperStyle}>
                      <div
                        className={styles.afterLoginEditProfileleftWrapperStyle}
                      >
                        <div className={styles.navMenuImgWrapperStyle}>
                          <img
                            src={blackLangIcon}
                            className={styles.imgStyle}
                          />
                        </div>
                        <p className={styles.afterLoginMenuTextStyle}>
                          Swedish
                        </p>
                      </div>
                      <div className={styles.navMenuImgWrapperStyle}>
                        <img src={blackCheckIcon} className={styles.imgStyle} />
                      </div>
                    </div>
                  </div>
                )} */}
              </div>
            )}
          </div>
        ) : (
          <div className={styles.navContainerStyle}>
            <div
              className={styles.logoImgContainerStyle}
              onClick={() => {
                navigate("/");
              }}
            >
              <img
                src={props.navWhite ? darkFoundiLogo : foundiLogo}
                className={styles.imgStyle}
              />
            </div>
            <div className={styles.navLinksContainerStyle}>
              <a
                onClick={() => {
                  navigate("/events");
                }}
                className={
                  props.navWhite
                    ? styles.navLinkText2Style
                    : styles.navLinkTextStyle
                }
              >
                Events
              </a>
              <a
                className={
                  props.navWhite
                    ? styles.navLinkText2Style
                    : styles.navLinkTextStyle
                }
                onClick={() => navigate("/about")}
              >
                About
              </a>
              {/* <a
                onClick={() => {
                  navigate("/products");
                }}
                className={
                  props.navWhite
                    ? styles.navLinkText2Style
                    : styles.navLinkTextStyle
                }
              >
                Products
              </a> */}
            </div>
            <div className={styles.navMenuContainerStyle}>
              {/* <div
                ref={divRefLanguage}
                className={styles.navMenuImgWrapperStyle}
                onClick={handleClickLang}
              >
                <img
                  src={props.navWhite ? blackLanguageIcon : languageIcon}
                  className={styles.imgStyle}
                />
              </div> */}
              <div className={styles.navMenuImgWrapperStyle}>
                <img
                  src={props.navWhite ? darkCartIcon : cartIcon}
                  className={styles.imgStyle}
                />
              </div>

              <div
                ref={divRefProfile}
                className={styles.navMenuProfileImgWrapperStyle}
                onClick={handleClick}
              >
                {/* <div className={styles.userProfileStyle}>
                  <img src={imageProfile} className={styles.imgStyle} />
                  <img src={downArrowProfile} className={styles.imgStyle} />
                </div> */}

                <img
                  src={props.navWhite ? blackProfileIcon : profileIcon}
                  className={styles.imgStyle}
                />
              </div>
            </div>
            <div className={styles.mobileNavMenuWrapperStyle}>
              <div className={styles.navMenuImgWrapperStyle}>
                <img
                  src={props.navWhite ? darkCartIcon : cartIcon}
                  className={styles.imgStyle}
                />
              </div>
              <div
                className={styles.navMenuWrapperStyle}
                onClick={() => setMenuBar(!menuBar)}
              >
                <img
                  src={props.navWhite ? darkMenuOptionIcon : menuOptionIcon}
                  className={styles.imgStyle}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <MenuPopover
        id={idPop}
        open={openLangPop}
        anchorEl={anchorE2}
        onClose={handleCloseLanguage}
      >
        <div className={styles.languagePopoverContainerStyle}>
          <div className={styles.afterLoginEditWrapperStyle}>
            <div className={styles.afterLoginEditProfileleftWrapperStyle}>
              <div className={styles.navMenuImgWrapperStyle}>
                <img src={blueLangIcon} className={styles.imgStyle} />
              </div>
              <p className={styles.afterLoginMenuTextStyle}>English</p>
            </div>
            <div className={styles.navMenuImgWrapperStyle}>
              <img src={blueCheckIcon} className={styles.imgStyle} />
            </div>
          </div>
          <div className={styles.afterLoginEditWrapperStyle}>
            <div className={styles.afterLoginEditProfileleftWrapperStyle}>
              <div className={styles.navMenuImgWrapperStyle}>
                <img src={blackLangIcon} className={styles.imgStyle} />
              </div>
              <p className={styles.afterLoginMenuTextStyle}>Swedish</p>
            </div>
            <div className={styles.navMenuImgWrapperStyle}>
              <img src={blackCheckIcon} className={styles.imgStyle} />
            </div>
          </div>
        </div>
      </MenuPopover> */}
      <MenuPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <div>
          <div className={styles.signUpTopContainerStyle}>
            <p className={styles.signUpTextStyle}>Sign up or Log in</p>
            <div
              className={styles.navMenuImgWrapperStyle}
              onClick={() => handleClose()}
            >
              <img src={darkCloseIcon} className={styles.imgStyle} />
            </div>
          </div>
          <div className={styles.signUpBottomContainerStyle}>
            <div className={styles.popOverSignUpImgWrapperStyle}>
              <img src={blueAddUserIcon} className={styles.imgStyle} />
            </div>
            <p className={styles.signUpdescriptionTextStyle}>
              Create an account or Log in with an existing account to access all
              of Foundi’s exciting features.
            </p>
            <div className={styles.btnContainerStyle}>
              <Button
                title="Log in"
                btnStyles={styles.loginBtnStyle}
                onClick={() => navigate("/login")}
              />
              <Button
                title="Sign up"
                btnStyles={styles.signUpBtnStyle}
                onClick={() => navigate("/signup")}
              />
            </div>
          </div>
        </div>
        {/* <div>
          <div className={styles.afterLoginTopContainerStyle}>
            <div className={styles.profileImageStyle}>
              <img src={imageProfile} className={styles.imgStyle} />
            </div>
            <div>
              <p className={styles.profileNameTextStyle}>Hank Green</p>
              <p className={styles.profileMailTextStyle}>hankgreen@gmail.com</p>
            </div>
          </div>
          <div className={styles.afterLoginMenuContainerStyle}>
            <div className={styles.afterLoginEditWrapperStyle}>
              <div className={styles.afterLoginEditProfileleftWrapperStyle}>
                <div className={styles.navMenuImgWrapperStyle}>
                  <img src={profileEditIcon} className={styles.imgStyle} />
                </div>
                <p className={styles.afterLoginMenuTextStyle}>Edit Profile</p>
              </div>
              <div className={styles.navMenuImgWrapperStyle}>
                <img src={darkRightArrow} className={styles.imgStyle} />
              </div>
            </div>
            <div className={styles.afterLoginEditWrapperStyle}>
              <div className={styles.afterLoginEditProfileleftWrapperStyle}>
                <div className={styles.navMenuImgWrapperStyle}>
                  <img src={profileMessageIcon} className={styles.imgStyle} />
                </div>
                <p className={styles.afterLoginMenuTextStyle}>Messages (2)</p>
              </div>
              <div className={styles.navMenuImgWrapperStyle}>
                <img src={darkRightArrow} className={styles.imgStyle} />
              </div>
            </div>
            <div className={styles.afterLoginEditWrapperStyle}>
              <div className={styles.afterLoginEditProfileleftWrapperStyle}>
                <div className={styles.navMenuImgWrapperStyle}>
                  <img src={profileHistoryIcon} className={styles.imgStyle} />
                </div>
                <p className={styles.afterLoginMenuTextStyle}>My Purchases</p>
              </div>
              <div className={styles.navMenuImgWrapperStyle}>
                <img src={darkRightArrow} className={styles.imgStyle} />
              </div>
            </div>
          </div>
          <div className={styles.afterLoginMenuContainerStyle}>
            <div className={styles.afterLoginEditProfileleftWrapperStyle}>
              <div className={styles.navMenuImgWrapperStyle}>
                <img src={profileLogoutIcon} className={styles.imgStyle} />
              </div>
              <p className={styles.logoutTextStyle}>Logout</p>
            </div>
          </div>
        </div> */}
      </MenuPopover>
    </div>
  );
};

export default Navbar;

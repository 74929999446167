import React, { useContext, createContext, useEffect, useState } from "react";
import EnglishStrings from "../resources/Strings/strings-en.json";
import SwedishStrings from "../resources/Strings/strings-swe.json";

export const AppDataContext = createContext({
  strings: EnglishStrings,
  currentLanguage: "",
  setLanguage: (_value) => {
    return null;
  },
});

export const AppDataProvider = (props) => {
  const defaultLang = "Swe";
  const [language, setLanguage] = useState(defaultLang);

  const initialize = async () => {
    setLanguage(localStorage.getItem("language") || defaultLang);
  };

  useEffect(() => {
    initialize();
  }, []);

  const strings = () => {
    switch (language) {
      case "Eng":
        return EnglishStrings;
      case "Swe":
        return SwedishStrings;
      default:
        return EnglishStrings;
    }
  };

  return (
    <AppDataContext.Provider
      value={{
        strings: strings(),
        currentLanguage: language,
        setLanguage: (value) => {
          localStorage.setItem("language", value);
          setLanguage(value);
        },
      }}
    >
      {props.children}
    </AppDataContext.Provider>
  );
};

export const useAppData = () => useContext(AppDataContext);

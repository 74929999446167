import React from "react";
import styles from "./styles.module.css";
import {
  ebayImg,
  awsImg,
  googleImg,
  twitchImg,
  iosImg,
} from "../../../../resources/Images/images";

const SocialSection = () => {
  return (
    <div className={styles.mainPartnerContainerStyle}>
      <div className={styles.partnerContainerStyle}>
        <p className={styles.partnerDescTextStyle}>
          We collaborated with the leading brands to ensure the best services
          for our end users.
        </p>

        <div className={styles.partnerImgContainerStyle}>
          <div className={styles.imgWrapperStyle}>
            <img src={ebayImg} className={styles.imgStyle} />
          </div>
          <div className={styles.imgWrapperStyle}>
            <img src={awsImg} className={styles.imgStyle} />
          </div>
          <div className={styles.imgWrapperStyle}>
            <img src={googleImg} className={styles.imgStyle} />
          </div>
          <div className={styles.imgWrapperStyle}>
            <img src={twitchImg} className={styles.imgStyle} />
          </div>
          <div className={styles.imgWrapperStyle}>
            <img src={iosImg} className={styles.imgStyle} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialSection;

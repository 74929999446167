import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const ModalPopUp = ({ children, open, handleClose }) => {
  const { width, height } = useWindowDimensions();

  const [mobileModal, setMobileModal] = useState(false);
  useEffect(() => {
    if (width <= 576) {
      setMobileModal(true);
    } else {
      setMobileModal(false);
    }
  }, [width]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        top: !mobileModal ? 0 : "auto",
        border: "none",
        outline: "none",
      }}
    >
      {children}
    </Modal>
  );
};

export default ModalPopUp;

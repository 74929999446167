import "./App.css";
import AppRoutes from "./routing";
import { AppDataProvider } from "./providers/AppDataProvider";

function App() {
  return (
    <AppDataProvider>
      <div className="App">
        <AppRoutes />
      </div>
    </AppDataProvider>
  );
}

export default App;

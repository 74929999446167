import React, { useState } from "react";
import styles from "./styles.module.css";
import {
  darkCloseIcon,
  downArrowIcon,
  upArrowIcon,
  blackCheckIcon,
  product2Img,
  redDeleteIcon,
  minusIcon,
  plusIcon,
  orderPlacedImg,
} from "../../resources/Images/images";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import ModalPopUp from "../../components/ModalBox";
import { useNavigate, useLocation } from "react-router-dom";

const CheckOutPage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [contactDetails, setContactDetails] = useState(true);
  const [address, setAddress] = useState(true);
  const [payment, setPayment] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  const LeftInputConatainers = () => {
    return (
      <div className={styles.checkOutPageInsideContainerStyle}>
        <div className={styles.checkoutPageInputContainerStyle}>
          <div
            className={styles.checkoutHeaderContainerStyle}
            onClick={() => setContactDetails(!contactDetails)}
          >
            <div className={styles.checkOutHeaderInsideContainerStyle}>
              <div className={styles.checkIconWrapperStyle}>
                <img src={blackCheckIcon} className={styles.imgStyle} />
              </div>
              <p className={styles.contactDetailsTextStyle}>Contact details</p>
            </div>
            <div>
              <img src={downArrowIcon} className={styles.imgStyle} />
            </div>
          </div>
          {contactDetails && (
            <div className={styles.contactInputContainerStyle}>
              <div className={styles.contactDetailsInputContainerStyle}>
                <TextInput
                  textInput={true}
                  labelName={"First name"}
                  placeholderName={"Enter first name"}
                  type={"text"}
                  onChangeText={(e) => console.log(e.target.value)}
                />
                <TextInput
                  textInput={true}
                  labelName={"Last name"}
                  placeholderName={"Enter last name"}
                  type={"text"}
                  onChangeText={(e) => console.log(e.target.value)}
                />
              </div>
              <div className={styles.contactDetailsInputContainerStyle}>
                <TextInput
                  textInput={true}
                  labelName={"Email address"}
                  placeholderName={"Enter your email ID"}
                  type={"text"}
                  onChangeText={(e) => console.log(e.target.value)}
                />
              </div>
              <div className={styles.contactDetailsInputContainerStyle}>
                <TextInput
                  phoneNumber={true}
                  countryCode={"+42"}
                  labelName={"Phone number"}
                  placeholderName={"Enter your phone No."}
                  type={"text"}
                  onChangeText={(e) => console.log(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
        <div className={styles.checkoutPageInputContainerStyle}>
          <div
            className={styles.checkoutHeaderContainerStyle}
            onClick={() => setAddress(!address)}
          >
            <div className={styles.checkOutHeaderInsideContainerStyle}>
              <div className={styles.checkIconWrapperStyle}>
                <img src={blackCheckIcon} className={styles.imgStyle} />
              </div>
              <p className={styles.contactDetailsTextStyle}>Address info</p>
            </div>
            <div>
              <img src={downArrowIcon} className={styles.imgStyle} />
            </div>
          </div>
          {address && (
            <div className={styles.contactInputContainerStyle}>
              <div className={styles.contactDetailsInputContainerStyle}>
                <TextInput
                  textInput={true}
                  labelName={"Door no, Floor, Building/Apt, & Street"}
                  placeholderName={"Enter your email ID"}
                  type={"text"}
                  onChangeText={(e) => console.log(e.target.value)}
                />
              </div>
              <div className={styles.contactDetailsInputContainerStyle}>
                <TextInput
                  textInput={true}
                  labelName={"City"}
                  placeholderName={"Enter first name"}
                  type={"text"}
                  onChangeText={(e) => console.log(e.target.value)}
                />
                <TextInput
                  textInput={true}
                  labelName={"ZIP code"}
                  placeholderName={"Enter last name"}
                  type={"text"}
                  onChangeText={(e) => console.log(e.target.value)}
                />
              </div>
              <div className={styles.contactDetailsInputContainerStyle}>
                <TextInput
                  textInput={true}
                  labelName={"State & Country"}
                  placeholderName={"Enter your phone No."}
                  type={"text"}
                  onChangeText={(e) => console.log(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
        <div className={styles.checkoutPageInputContainerStyle}>
          <div
            className={styles.checkoutHeaderContainerStyle}
            onClick={() => setPayment(!payment)}
          >
            <div className={styles.checkOutHeaderInsideContainerStyle}>
              <div className={styles.checkIconWrapperStyle}>
                <img src={blackCheckIcon} className={styles.imgStyle} />
              </div>
              <p className={styles.contactDetailsTextStyle}>Payment</p>
            </div>
            <div>
              <img src={downArrowIcon} className={styles.imgStyle} />
            </div>
          </div>
          {payment && (
            <div className={styles.contactInputContainerStyle}>
              <div className={styles.contactDetailsInputContainerStyle}>
                <TextInput
                  textInput={true}
                  labelName={"Card holder name"}
                  placeholderName={"Enter name on the card"}
                  type={"text"}
                  onChangeText={(e) => console.log(e.target.value)}
                />
              </div>

              <div className={styles.contactDetailsInputContainerStyle}>
                <TextInput
                  textInput={true}
                  labelName={"Credit or Debit card No."}
                  placeholderName={"Enter 16 digit card No."}
                  type={"text"}
                  onChangeText={(e) => console.log(e.target.value)}
                />
              </div>
              <div className={styles.contactDetailsInputContainerStyle}>
                <TextInput
                  textInput={true}
                  labelName={"Expiry date"}
                  placeholderName={"MM/YY"}
                  type={"text"}
                  onChangeText={(e) => console.log(e.target.value)}
                />
                <TextInput
                  textInput={true}
                  labelName={"CVV"}
                  placeholderName={"XYZ"}
                  type={"text"}
                  onChangeText={(e) => console.log(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  const RightOrderContainery = () => {
    return (
      <div className={styles.orderSummeryStyle}>
        <div className={styles.orderedProductsSummeryContainerStyle}>
          <p className={styles.orderSummeryTextStyle}>Order summary</p>
          <div className={styles.noOfOrderedProductsContainerStyle}>
            <div className={styles.orderProductContainerStyle}>
              <div className={styles.orderProductImgWrapperStyle}>
                <img src={product2Img} className={styles.imgStyle} />
              </div>
              <div className={styles.orderedProductRightContainerStyle}>
                <div className={styles.orderProductPricesContainerStyle}>
                  <p className={styles.orderProductTagsTextStyle}>
                    Keychain tags - A pack of 4
                  </p>
                  <p className={styles.orderProductPriceTextStyle}>
                    Price (x1): $ 90.00
                  </p>
                </div>
                <div className={styles.orderProductDetailsContainerStyle}>
                  <div className={styles.addMinusContainerSTyle}>
                    <div className={styles.addIconWrapperStyle}>
                      <img src={minusIcon} className={styles.imgStyle} />
                    </div>
                    <p className={styles.productNumberStyle}>01</p>
                    <div className={styles.addIconWrapperStyle}>
                      <img src={plusIcon} className={styles.imgStyle} />
                    </div>
                  </div>
                  <div className={styles.iconWrapperStyle}>
                    <img src={redDeleteIcon} className={styles.imgStyle} />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.orderProductContainerStyle}>
              <div className={styles.orderProductImgWrapperStyle}>
                <img src={product2Img} className={styles.imgStyle} />
              </div>
              <div className={styles.orderedProductRightContainerStyle}>
                <div className={styles.orderProductPricesContainerStyle}>
                  <p className={styles.orderProductTagsTextStyle}>
                    Keychain tags - A pack of 4
                  </p>
                  <p className={styles.orderProductPriceTextStyle}>
                    Price (x1): $ 90.00
                  </p>
                </div>
                <div className={styles.orderProductDetailsContainerStyle}>
                  <div className={styles.addMinusContainerSTyle}>
                    <div className={styles.addIconWrapperStyle}>
                      <img src={minusIcon} className={styles.imgStyle} />
                    </div>
                    <p className={styles.productNumberStyle}>01</p>
                    <div className={styles.addIconWrapperStyle}>
                      <img src={plusIcon} className={styles.imgStyle} />
                    </div>
                  </div>
                  <div className={styles.iconWrapperStyle}>
                    <img src={redDeleteIcon} className={styles.imgStyle} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.dividerStyle}></div>
        <div className={styles.orderedProductsSummeryContainerStyle}>
          <TextInput
            textInput={true}
            placeholderName={"Enter discount code"}
            type={"text"}
            onChangeText={(e) => console.log(e.target.value)}
          />
          <div className={styles.costAndPayContainerStyle}>
            <div className={styles.costListContainerStyle}>
              <div className={styles.productPriceContainerStyle}>
                <p className={styles.priceSubHeadTextStyle}>Subtotal</p>
                <p className={styles.costTextStyle}>$180.00</p>
              </div>
              <div className={styles.productPriceContainerStyle}>
                <p className={styles.priceSubHeadTextStyle}>
                  Shipping&nbsp;
                  <span className={styles.fillAddressTextStyle}>
                    (Fill address details)
                  </span>
                </p>
              </div>
              <div className={styles.productPriceContainerStyle}>
                <p className={styles.priceSubHeadTextStyle}>Tax & extras</p>
                <p className={styles.costTextStyle}>+ $0</p>
              </div>
              <div className={styles.productPriceContainerStyle}>
                <p className={styles.priceSubHeadTextStyle}>Discount</p>
                <p className={styles.costTextStyle}>- $0</p>
              </div>
            </div>
            <div className={styles.dividerStyle}></div>
            <div className={styles.totalPriceContainerStyle}>
              <p className={styles.totalTextStyle}>
                Total
                <span className={styles.withoutShippingtextStyle}>
                  without shipping
                </span>
              </p>
              <p className={styles.totalPriceTextStyle}>$180.00</p>
            </div>
            <Button
              title="Pay - $180.00"
              btnStyles={styles.payBtnStyle}
              onClick={() => setOpen(true)}
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={styles.checkoutPageMainContainerStyle}>
      <div className={styles.closeContainerStyle}>
        <p className={styles.checkoutTextStyle}>Checkout</p>
        <div
          className={styles.closeIconWrapperStyle}
          onClick={() => navigate(-1)}
        >
          <img src={darkCloseIcon} className={styles.imgStyle} />
        </div>
      </div>
      <div className={styles.checkOutGridContainerStyle}>
        {LeftInputConatainers()}
        {RightOrderContainery()}
      </div>
      <ModalPopUp open={open} handleClose={handleClose}>
        <div className={styles.modalContainerStyle}>
          <div className={styles.orderPlacedImgWrapperStyle}>
            <img src={orderPlacedImg} className={styles.imgStyle} />
          </div>
          <div className={styles.modalInsideContainerStyle}>
            <div className={styles.textContainerStyle}>
              <p className={styles.modalHeadertextStyle}>
                Yay! Your purchase is successful.
              </p>
              <p className={styles.modalDescTextStyle}>
                We’ve sent you a mail with more information regarding your
                purchase. Have a good day, we will make sure your order reaches
                you soon.
              </p>
            </div>
            <div className={styles.btnContainerStyle}>
              <Button
                title="Track order"
                btnStyles={styles.trackOrderBtnStyle}
                onClick={() => console.log("888")}
              />
              <Button
                title="I’ll do it later"
                btnStyles={styles.doitLaterBtnStyle}
                onClick={() => setOpen(false)}
              />
            </div>
          </div>
        </div>
      </ModalPopUp>
    </div>
  );
};

export default CheckOutPage;

import React, { useState } from "react";
import styles from "./styles.module.css";
import { darkCloseIcon, googleIcon } from "../../resources/Images/images";
import { useNavigate } from "react-router-dom";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";

const LoginPage = () => {
  const navigate = useNavigate();
  const [pwVisible, setPwVisible] = useState(false);
  const TopNavSection = () => {
    return (
      <div className={styles.closeContainerStyle}>
        <p className={styles.backTextStyle}>Back</p>
        <div
          className={styles.closeIconWrapperStyle}
          onClick={() => navigate(-1)}
        >
          <img src={darkCloseIcon} className={styles.imgStyle} />
        </div>
      </div>
    );
  };
  const step1SignUp = () => {
    return (
      <div className={styles.loginInsideContainerStyle}>
        <div className={styles.loginAccountContainerStyle}>
          <div className={styles.topContainerStyle}>
            <p className={styles.headerTextStyle}>Hello there, welcome back!</p>
            <p className={styles.noAccTextStyle}>
              No account?&nbsp;
              <span className={styles.signUpTextStyle}> Sign up</span>
            </p>
          </div>
          <div className={styles.inputContainerStyle}>
            <TextInput
              textInput={true}
              labelName={"Email"}
              placeholderName={"Enter your email ID"}
              type={"text"}
              onChangeText={(e) => console.log(e.target.value)}
            />
            <div>
              <TextInput
                textInput={true}
                labelName={"Password"}
                passWord={true}
                visible={pwVisible}
                onClick={() => setPwVisible(!pwVisible)}
                placeholderName={"6+ characters, 1 capital"}
                type={"text"}
                onChangeText={(e) => console.log(e.target.value)}
              />
              <p className={styles.forgetTextStyle}>Forgot password</p>
            </div>
          </div>

          <div className={styles.buttonContainerStyle}>
            <Button
              title="Log in"
              btnStyles={styles.loginBtnStyle}
              // onClick={() => setOpen(true)}
            />
            <Button
              image={googleIcon}
              title="Continue with Google"
              btnStyles={styles.googleBtnStyle}
              // onClick={() => setOpen(true)}
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={styles.loginContainerStyle}>
      {TopNavSection()}
      {step1SignUp()}
    </div>
  );
};

export default LoginPage;

import React from "react";
import styles from "./styles.module.css";
import Navbar from "../../components/NavBar";
import ProductDetailSection from "./Sections/ProductDetailSection";
import ReviewsSection from "./Sections/ReviewsSection";
import CustomerLikedProductsSection from "./Sections/CustomerLikedSection";
import Footer from "../../components/Footer";

const ProductDetailsPage = () => {
  return (
    <div className={styles.mainContainerStyle}>
      <div className={styles.mainInsideContainerStyle}>
        <Navbar navWhite={true} />
      </div>
      {ProductDetailSection()}
      {ReviewsSection()}
      {CustomerLikedProductsSection()}
      {Footer()}
    </div>
  );
};

export default ProductDetailsPage;

import React from "react";
import Navbar from "../../components/NavBar";
import styles from "./styles.module.css";
import Footer from "../../components/Footer";
import AboutVideoSection from "./Sections/AboutvideoSection";
import AboutAppSection from "./Sections/AboutAppSection";
import AboutSubscriptionSection from "./Sections/AboutSubscriptionSection";
import AboutEliminatingPrblmSection from "./Sections/AboutEliminatingPrblmSection";

const AboutPage = () => {
  return (
    <div className={styles.mainContainerStyle}>
      <div className={styles.mainInsideContainerStyle}>
        <Navbar navWhite={true} />
      </div>
      <div className={styles.aboutContainerStyle}>
        {AboutVideoSection()}
        {AboutAppSection()}
        {AboutSubscriptionSection()}
        {AboutEliminatingPrblmSection()}
      </div>
      {Footer()}
    </div>
  );
};

export default AboutPage;
